<template>
  <section>
    <section v-if="link.externalLink || link.customEvent">
      <div @click="openLink" class="sidebar__link">
        <img v-if="getMenuItemIcon(link)" class="sidebar__link-icon" :src="getMenuItemIcon(link)"
             alt=""/>
        <p>{{ link.text ? link.text : $t(`adminTitles.${link.name}`) }}</p>
      </div>
    </section>
    <section v-else>
      <router-link
        v-if="linkAvailable(link)"
        :to="link.path"
        class="sidebar__link"
      >
        <img class="sidebar__link-icon" :src="getMenuItemIcon(link)" alt=""/>
        <img class="sidebar__link-icon--active" :src="getMenuItemIcon(link, true)" alt=""/>
        <p>{{ link.text ? link.text : $t(`adminTitles.${link.name}`) }}</p>
        <div v-if="link.name === 'tickets' && ticketsActive"
             class="sidebar__link-dot"></div>
      </router-link>
      <div v-else class="sidebar__link sidebar__link--disable">
        <img class="sidebar__link-icon" :src="getMenuItemIcon(link)" alt=""/>
        <p>{{ $t(`adminTitles.${link.name}`) }}</p>
        <div class="sidebar__link-locker">
          <BaseFeatureLocker
            :size="'s'"
            :color="'light'"
            :tooltip-text="$t(link.featureLockerText)"
          ></BaseFeatureLocker>
        </div>
      </div>
    </section>
  </section>
</template>
<script>
import { mapGetters } from 'vuex';
import { AUTH_LOGOUT } from '@/store/actions/auth';
import featureMixin from '@/mixins/featureMixin';
import BaseFeatureLocker
  from '@/views/RenovaDashboard/components/ui-kit/Cards/BaseFeatureLocker';

export default {
  name: 'SideBarLink',
  components: {
    BaseFeatureLocker,
  },
  data() {
    return {
      ticketsActive: false,
    };
  },
  mixins: [featureMixin],
  emits: ['linkClick'],
  computed: {
    ...mapGetters(['user', 'ticketList']),
  },
  props: {
    link: {
      type: Object,
    }
  },
  watch: {
    ticketList: {
      handler() {
        this.ticketsActive = this.ticketList.stats && this.ticketList.stats.commonCountTodo > 0;
      },
      deep: true,
    },
  },
  methods: {
    getMenuItemIcon(item, isActive) {
      return isActive ? item.renovaDashboardActiveIcon : item.renovaDashboardIcon;
    },
    logout() {
      this.$store.dispatch(AUTH_LOGOUT).then(() => {
        this.$router.push('/login');
      });
    },
    linkAvailable(item) {
      if (item.feature) {
        return this.featureAvailable(item.feature);
      }
      return true;
    },
    reloadPage() {
      window.location.reload();
    },
    openLink() {
      if (this.link.externalLink) {
        window.open(this.link.externalLink, '_blank');
      }
      this.$emit('linkClick');
    }
  },
};
</script>

<style lang="scss" scoped>
.sidebar__link {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  gap: 8px;
  border-radius: 8px;
  position: relative;

  &--disable {
    cursor: default;

    p, .sidebar__link-icon {
      opacity: 0.4;
      pointer-events: none;
    }
  }

  p {
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    max-width: 160px;
  }

  &--bottom {
    margin-top: auto;
  }

  .sidebar__link-dot {
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 12px;
    right: 8px;
    top: 14px;
    background-color: var(--solid-tangerine);
  }

  .sidebar__link-icon--active {
    display: none;
  }

  &.router-link-active {
    background: var(--solid-purple);

    p {
      color: white
    }
  ;

    .sidebar__link-icon {
      display: none;
    }

    .sidebar__link-icon--active {
      display: flex;
    }
  }

  img {
    width: 24px;
    height: 24px;
  }

  p {
    font-size: 14px;
    line-height: 16px;
    color: #D9D2FF;
  }
}

::v-deep .sidebar__link-locker {
  margin-left: auto;

  .feature-locker {
    position: relative;
    background-color: transparent !important;
  }
}
</style>
