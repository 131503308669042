<template>
  <div class="login-page__wrapper">
    <HrHeader :is-dark="true"/>
    <div class="login-page__container">
      <LoginFormContainer
        :title="activePageData.title"
        :text="activePageData.description"
        :btn-text="activePageData.btnText"
        :show-content="activePage !== 'emailSent'"
        :show-policy-text="activePage === 'login'"
        :btn-loading="btnLoading"
        :back-btn-text="activePageData.backBtnText"
        :centered-btn="activePage === 'emailSent'"
        @backClick="activePage = 'login'"
        @btnClick="activePageData.btnAction()"
      >
        <el-form
          ref="form"
          :model="form"
          v-if="activePage === 'login' || activePage === 'recovery'">
          <el-form-item
            prop="name"
            class="login-page__form-item"
            :class="{'login-page__form-item--error': errors.name}"
          >
            <div
              class="login-page__input"
              :class="{'mb_12' : activePage === 'login' }"
            >
              <el-input
                v-model="form.name"
                :placeholder="$t('login.EmailAddress') "
                ref="emailInput"
                @blur="errors.name ? formValid() : null"
              />
              <div v-if="errors.name" class="login-page__input-error">
                <img src="/img/renova-dashboard/icons/err-notification.svg" alt="err">
                <span class="body-text color--red">{{ errors.name }}</span>
              </div>
            </div>
          </el-form-item>
          <el-form-item
            v-if="activePage === 'login'"
            :class="{'login-page__form-item--error': errors.password}"
            prop="password" class="login-page__form-item">
            <div class="login-page__input mb_20">
              <el-input
                v-model="form.password"
                :type="showPassword ? 'text' : 'password'"
                @blur="errors.password ? formValid() : null"
                :placeholder="$t('placeholders.password')"
              />
              <img
                class="login-page__input-icon"
                :src="showPassword ? '/img/renova-dashboard/icons/hide-password.svg' : '/img/renova-dashboard/icons/show-password.svg'"
                alt="password-hide"
                @click="showPassword = !showPassword"
              />
              <div v-if="errors.password" class="login-page__input-error">
                <img src="/img/renova-dashboard/icons/err-notification.svg" alt="err">
                <span class="body-text color--red">{{ errors.password }}</span>
              </div>
            </div>
          </el-form-item>
          <div
            class="login-page__form-btns"
            v-if="activePage === 'login'"
          >
            <el-checkbox
              v-model="rememberMe"
            >
              {{ $t('login.RememberMe') }}
            </el-checkbox>
            <p class="color--purple cursor__pointer body-text"
               @click="openRecoveryPage()">{{ $t('login.ForgotPassword') }}</p>
          </div>
        </el-form>
      </LoginFormContainer>
    </div>
  </div>
</template>

<script>
import LoginFormContainer
  from '@/views/RenovaDashboard/LoginParts/components/LoginFormContainer';
import HrHeader from '@/views/RenovaDashboard/HR/components/HrHeader';
import { AUTH_REQUEST, PASSWORD_CHANGE_EMAIL_REQUEST } from '@/store/actions/auth';
import dayjs from 'dayjs';
import { getWhiteLabelConfig } from '../../../whitelabel-utils';

export default {
  name: 'LoginView',
  components: {
    HrHeader,
    LoginFormContainer,
  },
  computed: {
    activePageData() {
      switch (this.activePage) {
        case 'login':
          return {
            title: `${this.$t('login.WelcomeBackToRenova')} ${getWhiteLabelConfig().name}!`,
            btnText: this.$t('login.LogIn'),
            btnAction: this.logIn,
          };
        case 'recovery':
          return {
            title: this.$t('login.ForgotPassword'),
            description: this.$t('login.EnterYourAccountEmail'),
            btnText: this.$t('login.SendResetLink'),
            btnAction: this.changePassword,
            backBtnText: this.$t('login.BackToLogin'),
          };
        case 'emailSent':
          return {
            title: this.$t('login.EmailWasSent'),
            description: this.$t('login.WeSentYouPasswordReset'),
            btnText: this.$t('login.ReturnToLogin'),
            btnAction: this.openLoginPage,
          };
        default:
          return null;
      }
    },
  },
  data() {
    return {
      rememberMe: true,
      activePage: 'login',
      showPassword: false,
      form: {
        name: null,
        password: null,
      },
      errors: {
        name: null,
        password: null,
      },
      btnLoading: false,
    };
  },
  mounted() {
    document.addEventListener('keydown', this.handleEnterKeyPress);
    this.setupStyles();
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.handleEnterKeyPress);
  },
  methods: {
    setupStyles() {
      if (window.adminLayout === 'RenovaDashboardLayout') {
        import('@/assets/scss/renova/renova-ui-elements.scss');
      }
    },
    openLoginPage() {
      this.activePage = 'login';
    },
    openRecoveryPage() {
      this.activePage = 'recovery';
      setTimeout(() => {
        this.$refs.emailInput.focus();
      });
    },
    formValid() {
      this.errors = {
        email: null,
        password: null,
      };
      if (!this.form.name) {
        this.errors.name = this.$t('Please enter a valid email address');
      }
      if (!this.form.password && this.activePage === 'login') {
        this.errors.password = this.$t('Required field');
      }
      return !this.errors.name?.length && !this.errors.password?.length;
    },
    logIn() {
      if (this.formValid()) {
        this.btnLoading = true;
        this.$store.dispatch(AUTH_REQUEST, this.form).then(() => {
          this.btnLoading = false;
          this.setTokenExpiredDate();
          if (this.$route.name === 'hr-login') {
            this.$router.push('/hr/user-page');
          } else {
            this.$router.push('/admin/programs');
          }
        })
          .catch((err) => {
            if (err.response.status === 403) {
              this.errors.password = this.$t('adminTitles.authError');
            }
            this.btnLoading = false;
          });
      }
    },
    changePassword() {
      if (this.form.name && this.validateEmail(this.form.name)) {
        this.btnLoading = true;
        this.$store.dispatch(PASSWORD_CHANGE_EMAIL_REQUEST, this.form.name).then(() => {
          this.activePage = 'emailSent';
          this.btnLoading = false;
          this.errors.name = null;
        }).catch((err) => {
          this.btnLoading = false;
          this.errors.name = this.$t('login.NoActiveAccountFound');
        });
      } else {
        this.errors.name = this.$t('login.PleaseEnterValidEmailAddress');
      }
    },
    setTokenExpiredDate() {
      if (!this.rememberMe) {
        const currentDate = dayjs();
        const futureDate = currentDate.add(3, 'hour');
        localStorage.setItem('tokenExpired', futureDate.format('YYYY-MM-DD HH:mm:ss'));
      } else {
        localStorage.removeItem('tokenExpired');
      }
    },
    validateEmail(email) {
      const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
      return emailRegex.test(email);
    },
    handleEnterKeyPress(event) {
      if (event.key === 'Enter') {
        event.preventDefault();
        this.activePageData.btnAction();
      }
    },
  },
};
</script>

<style lang="scss">

.login-page__wrapper {
  height: 100vh;
}

.login-page__container {
  position: relative;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 56px);
  background: var(--solid-light-purple);
}

.login-page__input {
  min-height: 52px;
  position: relative;

  input {
    height: 52px !important;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  .login-page__input-icon {
    position: absolute;
    right: 16px;
    top: 18px;
    cursor: pointer;
  }
}

.login-page__form-btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.login-page__form-item {
  &--error {
    .el-input .el-input__inner {
      border: 1px solid var(--solid-red) !important;
    }
  }
}

.login-page__input-error {
  display: flex;
  gap: 8px;
  align-items: center;
  padding-bottom: 4px;
  padding-top: 8px;

  img {
    width: 16px;
    height: 16px;
  }
}
</style>
