export const CLIENT_COMPANIES_REQUEST = 'CLIENT_COMPANIES_REQUEST';
export const CLIENT_COMPANIES_SUCCESS = 'CLIENT_COMPANIES_SUCCESS';
export const CLIENT_COMPANY_DETAILS_REQUEST = 'CLIENT_COMPANY_DETAILS_REQUEST';
export const CLIENT_COMPANY_DETAILS_SUCCESS = 'CLIENT_COMPANY_DETAILS_SUCCESS';
export const CLIENT_COMPANY_SAVE_REQUEST = 'CLIENT_COMPANY_SAVE_REQUEST';

export const CLIENT_COMPANIES_CLEAR = 'CLIENT_COMPANIES_CLEAR';

export const CHANGE_CLIENT_COMPANIES_FILTER_FOR_PROGRAM = 'CHANGE_CLIENT_COMPANIES_FILTER_FOR_PROGRAM';
export const CHANGE_CLIENT_COMPANIES_SEARCH_QUERY_CHANGE = 'CHANGE_CLIENT_COMPANIES_SEARCH_QUERY_CHANGE';
