<template>
  <button
    class="base-button"
    :class="classes"
    :style="{
      'background' : type === 'solid' ? bgColor : '#FFFFFF',
      'border': borderColor ? `1px solid ${borderColor}` : null
    }"
    @click="onClick"
  >
    <slot/>
    <BasePreloader
      v-if="loading"
      size="s"
      :color="type === 'solid' ? 'white' : 'purple'"
    />
    <BaseFeatureLocker
      v-if="locked"
      :tooltip-text="lockerText"
      :size="'s'"
    />
  </button>
</template>

<script>

import BaseFeatureLocker from '@/views/RenovaDashboard/components/ui-kit/Cards/BaseFeatureLocker';
import BasePreloader from '@/views/RenovaDashboard/components/ui-kit/Buttons/BasePreloader';

export default {
  name: 'BaseButton',
  components: {
    BaseFeatureLocker,
    BasePreloader,
  },
  props: {
    type: {
      type: String,
      default: 'solid',
      required: false,
      validate: (value) => ['solid', 'outline', 'icon'].includes(value),
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    locked: {
      type: Boolean,
      default: false,
    },
    lockerText: {
      type: String,
      default: null,
    },
    bgColor: {
      type: String,
    },
    borderColor: {
      type: String,
    },
  },
  computed: {
    classes() {
      return {
        'base-button--outline': this.type === 'outline',
        'base-button--icon': this.type === 'icon',
        'base-button--fullWidth': this.fullWidth,
        'base-button--locked': this.locked,
      };
    },
  },
  methods: {
    onClick() {
      if (!this.locked) {
        this.$emit('click');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.base-button {
  font-family: 'Inter', serif;
  display: inline-flex;
  align-items: center;
  position: relative;
  gap: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  padding: 12px 16px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  cursor: pointer;
  color: #FFFFFF;
  background: var(--solid-purple);
  border: 1px solid rgba(0, 0, 0, 0.1);
  transition: .3s;
  overflow: hidden;
  white-space: nowrap;

  &--outline {
    color: var(--solid-purple);
    background: #FFFFFF;
    border: 1px solid var(--solid-purple);
    font-weight: 400;
  }

  &--icon {
    color: var(--solid-purple);
    background: #FFFFFF;
    border: 1px solid var(--solid-purple);
    padding: 12px;
  }

  &:disabled {
    cursor: default;
    opacity: 0.6;
  }

  &--fullWidth {
    width: 100%;
    justify-content: center;
  }

  &--locked {
    opacity: 0.4;
    cursor: default;
  }
}
</style>
<style lang="scss">
.base-button--locked {
  .feature-locker {
    background-color: transparent;
  }
}
</style>
