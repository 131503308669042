<template>
  <section>
    <div
      v-if="!fileUploadView && !loading"
      class="document-file"
      :class="{'document-file--disabled' : disabled}"
    >
      <a :href="link"
         download
         target="_blank"
         class="document-file__link">
        <div
          v-if="typeInfo"
          class="document-file__type"
          :style="{'background-color': typeInfo ? typeInfo.color : '#58DB4D'}"
        >
          {{ typeInfo ? typeInfo.text : getFileTypeFromLink(link) }}
        </div>
        <img
          v-if="type === 'img' || type === 'avatar' || type === 'logo' || type === 'companyLogo'"
          alt="'img'"
          class="document-file__img"
          :class="{
            'document-file__img--avatar': type === 'avatar',
            'document-file__img--logo': type === 'logo',
            'document-file__img--companyLogo': type === 'companyLogo',
          }"
          :src="link"
        />
        <div class="document-file__info body-text">
          {{ name || getFileNameFromLink(link) }}
        </div>
        <div v-if="link && !deleteBtn && !editBtn" class="document-file__btn"></div>
      </a>
      <div
        v-if="deleteBtn"
        @click="$emit('delete')"
        class="document-file__delete-btn"></div>
      <div
        v-if="editBtn"
        class="document-file__edit-btn">
        <input
          type="file"
          name="file"
          id="file-input"
          ref="fileInput"
          :accept="acceptFormats"
          class="document-file__input"
          @change="onFileChange"
        >
      </div>
    </div>
    <div
      v-if="fileUploadView && !loading"
      class="document-file-upload">
      <div class="document-file-upload__icon"></div>
      <div class="document-file-upload__texts">
        <p class="body-text color--black-purple"
           v-html="$t('adminTitles.dragAndDropOrClickToImportFile')"></p>
        <p class="caption color--dark-purple">{{ acceptFormatsText }}</p>
      </div>
      <input
        type="file"
        name="file"
        id="file-input"
        ref="fileInput"
        :accept="acceptFormats"
        class="document-file__input"
        @change="onFileChange"
      >
    </div>
    <div class="document-file document-file--loading" v-if="loading">
      <BasePreloader :color="'purple'" :size="'l'"></BasePreloader>
    </div>
  </section>
</template>

<script>
import BasePreloader from '@/views/RenovaDashboard/components/ui-kit/Buttons/BasePreloader';

export default {
  name: 'BaseDocument',
  emits: ['delete', 'fileChange'],
  components: {
    BasePreloader,
  },
  props: {
    type: {
      type: String,
      default: 'document',
    },
    link: {
      type: String,
    },
    name: {
      type: String,
    },
    deleteBtn: {
      type: Boolean,
      default: false,
    },
    editBtn: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    fileUploadView: {
      type: Boolean,
      default: false,
    },
    acceptFormats: {
      type: String,
      default: '',
    },
    acceptFormatsText: {
      type: String,
      default: '',
    },
  },
  computed: {
    typeInfo() {
      if (this.type === 'text/csv' || this.type === 'csv') {
        return {
          text: 'CSV',
          color: '#FF8D7A',
        };
      }
      if (this.type === 'application/pdf' || this.type === 'pdf') {
        return {
          text: 'PDF',
          color: '#58DB4D',
        };
      }
      if (this.type === 'xlsx') {
        return {
          text: 'XLSX',
          color: '#58DB4D',
        };
      }
      return null;
    },
  },
  methods: {
    onClick() {
      this.$emit('click');
    },
    onFileChange(file) {
      this.$emit('fileChange', file);
      document.getElementById('file-input').value = null;
    },
    getFileTypeFromLink(name) {
      const nameParts = name?.split('.');
      if (nameParts) {
        const fileExtension = nameParts[nameParts.length - 1];
        return fileExtension;
      }
    },
    getFileNameFromLink(link) {
      return link?.split('/')?.pop();
    },
  },
};
</script>

<style lang="scss" scoped>
.document-file, .document-file-upload {
  display: flex;
  align-items: center;
  width: 100%;
  padding-right: 16px;
  border: 1px solid var(--solid-lavender-grey);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.02);
  background-color: #FFFFFF;
  min-height: 72px;
  border-radius: 8px;
  position: relative;
}

.document-file--disabled {
  pointer-events: none;
  background-color: #F8F8F8;

  .document-file__info {
    color: var(--solid-dark-purple);
  }

  .document-file__btn, .document-file__delete-btn, .document-file__edit-btn, .document-file-upload__icon {
    display: none;
  }
}

.document-file-upload {
  padding: 16px;
  cursor: pointer;
}

.document-file__link {
  display: flex;
  align-items: center;
  width: calc(100% - 36px);
  height: 100%;
  padding: 16px 0px 16px 16px;
  cursor: pointer;
}

.document-file--loading {
  justify-content: center;
}

.document-file__img {
  width: 60px;
  object-fit: cover;
  margin-right: 16px;
  max-height: 100px;
  border-radius: 5px;

  &--avatar {
    height: 60px;
    max-height: 60px;
    border-radius: 60px;
  }

  &--logo {
    height: 40px;
    width: 40px;
    border-radius: 8px;
  }

  &--companyLogo {
    height: 80px;
    width: 80px;
    border-radius: 8px;
  }
}

.document-file__type {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 40px;
  height: 40px;
  color: white;
  border-radius: 8px;
  font-family: 'Inter', serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  margin-right: 16px;
  text-transform: uppercase;
}

.document-file__info {
  //display: flex;
  align-items: center;
  width: calc(100% - 92px);
  max-width: 600px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-decoration: none;
  color: var(--solid-black-purple);
}

.document-file__btn, .document-file__delete-btn, .document-file__edit-btn, .document-file-upload__icon {
  width: 20px;
  height: 20px;
  margin-left: 16px;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}

.document-file__edit-btn {
  width: 16px;
  height: 16px;
}

.document-file__btn {
  margin-left: auto;
  background-image: url(/public/img/renova-dashboard/icons/download.svg);
}

.document-file__delete-btn {
  background-image: url(/public/img/renova-dashboard/icons/trash.svg);
}

.document-file__edit-btn {
  background-image: url(/public/img/renova-dashboard/icons/edit-3.svg);
}

.document-file__input {
  opacity: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
}

.document-file-upload__icon {
  background-image: url(/public/img/renova-dashboard/icons/upload.svg);
  margin-left: 0;
  margin-right: 16px;
}

.document-file-upload__texts {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
</style>
