import axios from 'axios';
import {
  CLIENTS_CHAT_MESSAGES_REQUEST,
  CLIENTS_CHAT_MESSAGE_SEND_REQUEST,
  CLIENTS_CHAT_CONFIG_REQUEST,
  CLIENTS_CHAT_CONVERSATIONS_DETAILS_REQUEST,
  CLIENTS_CHAT_ATTACH_FILE,
  CLIENTS_CHAT_DELETE_ATTACHMENT,
  CLIENTS_CHAT_WEBSOCKET_RECEIVED,
  CLIENTS_CHAT_GET_SUGGESTION_REQUEST,
  CLIENTS_CHAT_MESSAGES_READ_REQUEST,
  CLIENTS_CHAT_WEBSOCKET_MESSAGE_READ_RECEIVED,
  CLIENTS_CHAT_MESSAGE_EDIT_REQUEST,
  CLIENTS_CHAT_MESSAGE_DELETE_REQUEST, CLIENTS_CHAT_CLEAR_ATTACHMENTS,
} from '../actions/chat';

const state = {
  clientsChatWebSocketURL: null,
  clientsChatConversationChannel: null,
  clientsChatConversationId: '',
  clientsChatMessages: [],
  clientsChatAttachedFiles: [],
  clientsChat: {
    chatId: null,
    items: [],
    itemsErrorCode: null,
    itemsLoading: false,
    sendLoading: false,
    sendMessageError: false,
  },
};

const getters = {
  clientsChat: () => state.clientsChat,
  clientsChatWebSocketURL: () => state.clientsChatWebSocketURL,
  clientsChatConversationChannel: () => state.clientsChatConversationChannel,
  clientsChatConversationId: () => state.clientsChatConversationId,
  clientsChatMessages: () => state.clientsChatMessages,
  clientsChatAttachedFiles: () => state.clientsChatAttachedFiles,
};

const actions = {
  /**
   * Get chat config request (web socket url)
   * @returns {Promise<void>}
   */
  [CLIENTS_CHAT_CONFIG_REQUEST]({commit}) {
    return axios.post('/insurance/conversation/config/detail')
      .then((resp) => {
        commit(CLIENTS_CHAT_CONFIG_REQUEST, resp);
        return resp.data.connectUrl;
      });
  },
  /**
   * Get conversation details (channel and id) request
   * @returns {Promise<void>}
   */
  [CLIENTS_CHAT_CONVERSATIONS_DETAILS_REQUEST]({commit}, payload) {
    return axios.post(
      '/insurance/conversation/detail',
      payload,
    )
      .then((resp) => {
        commit(CLIENTS_CHAT_CONVERSATIONS_DETAILS_REQUEST, resp);
        return resp.data;
      });
  },
  /**
   * API request for chat messages history
   * @param commit
   * @param dispatch
   * @param payload {conversationId: number, lastNumber: number}
   * @returns {Promise}
   */
  [CLIENTS_CHAT_MESSAGES_REQUEST]({commit, dispatch}, payload) {
    return axios.post('/insurance/conversation/message/list', {
      conversationId: payload.conversationId,
      to: payload.lastNumber,
    }).then((resp) => {
      commit(CLIENTS_CHAT_MESSAGES_REQUEST, payload.messages ? {messages: [...resp.data.messages, ...payload.messages]} : resp.data);
      if (!payload.preventReadMessage) {
        dispatch(CLIENTS_CHAT_MESSAGES_READ_REQUEST, payload);
      }
    });
  },
  [CLIENTS_CHAT_MESSAGES_READ_REQUEST]({commit, dispatch, state}, payload) {
    return axios.post('/insurance/conversation/message/mark-read', {
      conversationId: payload.conversationId,
    }).then(() => {
      state.clientsChatMessages = [...state.clientsChatMessages.map((message) => ({
        ...message,
        supportIsRead: true,
      }))];
    });
  },
  /**
   * Send message request
   * @param commit
   * @param dispatch
   * @param state
   * @param payload
   * @returns {Promise}
   */
  [CLIENTS_CHAT_MESSAGE_SEND_REQUEST]({commit, dispatch, state}, payload) {
    return axios.post('/insurance/conversation/message/send', {
      cdnFileIds: state.clientsChatAttachedFiles.map((file) => file.cdnFileId),
      conversationId: payload.conversationId,
      text: payload.message,
      ticketId: payload.ticketId,
    }).then((resp) => {
      commit(CLIENTS_CHAT_MESSAGE_SEND_REQUEST, resp.data);
      return resp.data;
    });
  },
  [CLIENTS_CHAT_CLEAR_ATTACHMENTS]({commit}) {
    commit(CLIENTS_CHAT_CLEAR_ATTACHMENTS);
  },
  [CLIENTS_CHAT_MESSAGE_EDIT_REQUEST]({commit, dispatch, state}, payload) {
    return axios.post('/insurance/conversation/message/edit', payload)
      .then((resp) => {
        commit(CLIENTS_CHAT_MESSAGE_EDIT_REQUEST, resp.data.message.editMessageDetails);
      });
  },
  [CLIENTS_CHAT_MESSAGE_DELETE_REQUEST]({commit, dispatch, state}, messageId) {
    return axios.post('/insurance/conversation/message/delete', {
      conversationId: state.clientsChatConversationId,
      messageId,
    })
      .then(() => {
        commit(CLIENTS_CHAT_MESSAGE_DELETE_REQUEST, messageId);
      });
  },
  /**
   * Attach file to message
   * @param commit
   * @param dispatch
   * @param payload
   * @returns {Promise<void>}
   */
  [CLIENTS_CHAT_ATTACH_FILE]({commit, dispatch}, payload) {
    // upload file Multipart/form-data
    const formData = new FormData();
    formData.append('file', payload.file);
    return axios.post('/insurance/conversation/file/save', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then((resp) => {
      commit(CLIENTS_CHAT_ATTACH_FILE, resp.data);
      return resp.data;
    });
  },
  [CLIENTS_CHAT_GET_SUGGESTION_REQUEST]({commit, dispatch}, conversationId) {
    return axios.post(
      'insurance/conversation/ai/suggest/list',
      {
        conversationId,
      },
    )
      .then((resp) => resp.data.items[0].content);
  },
};

const mutations = {
  [CLIENTS_CHAT_CONFIG_REQUEST]: (state, resp) => {
    state.clientsChatWebSocketURL = resp.data.connectUrl;
  },
  [CLIENTS_CHAT_CONVERSATIONS_DETAILS_REQUEST]: (state, resp) => {
    state.clientsChatConversationChannel = resp.data.conversationChannel;
    state.clientsChatConversationId = resp.data.conversationId;
  },
  [CLIENTS_CHAT_MESSAGES_REQUEST]: (state, data) => {
    state.clientsChatMessages = data.messages;
  },
  [CLIENTS_CHAT_MESSAGE_SEND_REQUEST]: (state) => {
    // Will clear attached files after sending message
    state.clientsChatAttachedFiles = [];
  },
  [CLIENTS_CHAT_ATTACH_FILE]: (state, data) => {
    state.clientsChatAttachedFiles.push(data);
  },
  [CLIENTS_CHAT_DELETE_ATTACHMENT]: (state, data) => {
    state.clientsChatAttachedFiles = state.clientsChatAttachedFiles.filter((file) => file.cdnFileId !== data.fileId);
  },
  [CLIENTS_CHAT_CLEAR_ATTACHMENTS]: (state) => {
    state.clientsChatAttachedFiles = [];
  },
  [CLIENTS_CHAT_WEBSOCKET_RECEIVED]: (state, data) => {
    state.clientsChatMessages.push(data);
  },
  [CLIENTS_CHAT_WEBSOCKET_MESSAGE_READ_RECEIVED]: (state) => {
    state.clientsChatMessages = state.clientsChatMessages.map((message) => ({
      ...message,
      clientIsRead: true,
    }));
  },
  [CLIENTS_CHAT_MESSAGE_EDIT_REQUEST]: (state, data) => {
    state.clientsChatMessages = state.clientsChatMessages.map((message) => {
      if (message.messageId === data.messageId) {
        return {
          ...message,
          text: data.text,
        };
      }
      return message;
    });
  },
  [CLIENTS_CHAT_MESSAGE_DELETE_REQUEST]: (state, messageId) => {
    state.clientsChatMessages = state.clientsChatMessages.filter((message) => message.messageId !== messageId);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
