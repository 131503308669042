<template>
  <div class="onboarding-integration">
    <p class="h2 mb_12 color--black-purple text_ac">
      {{ $t('onboarding.ConnectWhatsAppInOneClick') }}
    </p>
    <p class="body-text mb_32 onboarding-integration__description"
       v-html="$t('onboarding.ManageAllClientCommunication')">
    </p>
    <div
      class="whatsApp-modal__instruction">
      <div
        class="whatsApp-modal__instruction-texts body-text"
        v-html="$t('adminTitles.WhatsAppConnectionDescription')">
      </div>
      <div class="whatsApp-modal__instruction-qrCode">
        <qrcode-vue
          v-if="insuranceConfig && insuranceConfig.whatsAppUnofficialDetailsView && insuranceConfig.whatsAppUnofficialDetailsView.qrCode"
          :value="insuranceConfig.whatsAppUnofficialDetailsView.qrCode"
          :size="136"
          level="L"
          :foreground="'rgb(0, 0, 0)'"
        ></qrcode-vue>
        <BasePreloader v-else :color="'purple'" :size="'l'"></BasePreloader>
      </div>
    </div>
    <BaseButton
      :loading="btnLoading"
      :full-width="true"
      class="mt_32 mb_16"
      @click="resetWhatsApp()"
    >
      {{ $t('adminTitles.TryAgain') }}
    </BaseButton>
    <BaseTextButton
      @click="skipStep"
      class="color--purple">
      {{ $t('onboarding.SkipForNow') }}
    </BaseTextButton>
  </div>
</template>

<script>
import BaseTextButton from '@/views/RenovaDashboard/components/ui-kit/Buttons/BaseTextButton';
import { mapGetters } from 'vuex';
import BaseButton from '@/views/RenovaDashboard/components/ui-kit/Buttons/BaseButton';
import WhatsAppIntegration
  from '@/views/RenovaDashboard/IntegrationsParts/components/mixins/WhatsAppIntegration';
import QrcodeVue from 'qrcode.vue';
import { SAVE_INSURANCE_CONFIG } from '@/store/actions/onboarding';
import BasePreloader from '@/views/RenovaDashboard/components/ui-kit/Buttons/BasePreloader';

export default {
  name: 'OnboardingIntegrationStep',
  components: {
    BasePreloader,
    QrcodeVue,
    BaseButton,
    BaseTextButton
  },
  emits: ['skip', 'submitStep'],
  mixins: [WhatsAppIntegration],
  data() {
    return {
      insuranceConfig: null,
      interval: null,
      btnLoading: false,
    };
  },
  computed: {
    ...mapGetters(['onboarding', 'onboardingInsuranceCompany', 'selectedInsuranceProvider']),
  },
  created() {
    this.initWhatsUp();
  },
  methods: {
    skipStep() {
      this.$emit('skip');
    },
    onWhatsUpConnect() {
      this.$emit('skip');
    },
    resetWhatsApp() {
      this.btnLoading = true;
      this.$store.dispatch(SAVE_INSURANCE_CONFIG, {
        whatsAppUnofficialConnectionDelete: true
      }).then(() => {
        this.initWhatsUp();
        setTimeout(() => {
          this.btnLoading = false;
        }, 3000);
      });
    },
    initWhatsUp() {
      if (this.interval) {
        this.clearInterval();
      }
      if (this.onboardingInsuranceCompany?.whatsAppUnofficialDetailsView?.status === 'CONNECTED') {
        this.skipStep();
      } else if (!this.onboardingInsuranceCompany?.whatsAppUnofficialDetailsView) {
        this.$store.dispatch(SAVE_INSURANCE_CONFIG, {
          whatsAppUnofficialConnectionSave: {
            description: '',
          }
        }).then((res) => {
          this.setUpInterval();
        }).catch(() => {
          this.insuranceConfig = null;
        });
      } else {
        this.setUpInterval();
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.onboarding-integration {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 40px 70px 0px 70px;
  align-items: center;
}

.onboarding-integration__form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .el-form-item__error {
    position: relative !important;
  }

  input {
    padding-right: 71px !important;
  }
}

.onboarding-integration__form-input {
  position: relative;
}

.onboarding-integration__form-input-btn {
  position: absolute;
  right: 16px;
  top: 12px;
}

.onboarding-integration__description {
  line-height: 20px !important;
  color: var(--solid-black-purple);
  text-align: center;
}

.whatsApp-modal__instruction {
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

::v-deep .whatsApp-modal__instruction-texts {
  p {
    margin-bottom: 8px;
    line-height: 20px;
  }

  strong {
    font-weight: normal;
  }
}

.whatsApp-modal__instruction-qrCode {
  width: 136px;
  height: 136px;
  min-width: 136px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
