import axios from 'axios';
import i18n from '@/i18n';
import {
  CLIENT_COMPANIES_REQUEST,
  CLIENT_COMPANIES_SUCCESS,
  CLIENT_COMPANY_DETAILS_REQUEST,
  CHANGE_CLIENT_COMPANIES_FILTER_FOR_PROGRAM,
  CLIENT_COMPANY_DETAILS_SUCCESS,
  CLIENT_COMPANY_SAVE_REQUEST,
  CLIENT_COMPANIES_CLEAR,
  CHANGE_CLIENT_COMPANIES_SEARCH_QUERY_CHANGE,
} from '../actions/client-companies';

function FiltersDefault() {
  this.insuranceCompanyIds = [];
  this.limit = 1000;
  this.page = 1;
}

/**
 * Load search query from local storage
 * @returns {string}
 */
function loadCachedSearchQuery() {
  const cachedQuery = localStorage.getItem('clientCompaniesSearchQuery');
  if (cachedQuery) {
    return cachedQuery;
  }
  return '';
}

const state = {
  clientCompaniesList: {
    items: [],
    totalCount: 1,
    loading: false,
  },
  clientCompanyDetails: {
    company: null,
    loading: false,
    saveLoading: false,
  },
  filters: new FiltersDefault(),
  clientCompaniesSearchQuery: loadCachedSearchQuery(),
};

const getters = {
  clientCompaniesList: () => state.clientCompaniesList,
  clientCompanyDetails: () => state.clientCompanyDetails,
  clientCompaniesSearchQuery: () => state.clientCompaniesSearchQuery,
};

const actions = {
  [CLIENT_COMPANIES_REQUEST]({ commit, state }, all) {
    commit(CLIENT_COMPANIES_REQUEST);

    if (all) {
      state.filters = new FiltersDefault();
    }

    const filters = JSON.parse(JSON.stringify(state.filters));
    filters.page -= 1;

    return axios.post(
      '/insurance/client-company/list',
      filters,
      { headers: { 'X-Locale': i18n.locale } },
    )
      .then((resp) => {
        commit(CLIENT_COMPANIES_SUCCESS, resp.data);
        return Promise.resolve();
      })
      .catch((err) => {
        commit(CLIENT_COMPANIES_REQUEST);
        console.log(err.response.data);
        return Promise.reject(err.response.data);
      });
  },
  [CLIENT_COMPANY_DETAILS_REQUEST]({ commit }, company) {
    commit(CLIENT_COMPANY_DETAILS_REQUEST);
    return axios.post(
      '/insurance/client-company/detail',
      company,
      { headers: { 'X-Locale': i18n.locale } },
    )
      .then((resp) => {
        commit(CLIENT_COMPANY_DETAILS_SUCCESS, resp.data);
        return Promise.resolve();
      })
      .catch((err) => {
        commit(CLIENT_COMPANY_DETAILS_REQUEST);
        console.log(err.response.data);
        return Promise.reject(err.response.data);
      });
  },
  [CLIENT_COMPANY_SAVE_REQUEST]({ commit }, company) {
    commit(CLIENT_COMPANY_SAVE_REQUEST);
    return axios.post('/insurance/client-company/save', company, { headers: { 'X-Locale': i18n.locale } })
      .then((resp) => {
        if (company.clientCompanyId) {
          commit(CLIENT_COMPANY_DETAILS_SUCCESS, resp.data);
        }
        commit(CLIENT_COMPANY_SAVE_REQUEST);
        return Promise.resolve(resp.data);
      })
      .catch((err) => {
        commit(CLIENT_COMPANY_SAVE_REQUEST);
        console.log(err.response.data);
        return Promise.reject(err.response.data);
      });
  },
  [CHANGE_CLIENT_COMPANIES_FILTER_FOR_PROGRAM]({ commit, dispatch }, data) {
    commit(CHANGE_CLIENT_COMPANIES_FILTER_FOR_PROGRAM, data);
    dispatch(CLIENT_COMPANIES_REQUEST);
  },
};

const mutations = {
  [CLIENT_COMPANIES_REQUEST]: (state) => {
    state.clientCompaniesList.loading = true;
  },
  [CLIENT_COMPANIES_SUCCESS]: (state, resp) => {
    state.clientCompaniesList.loading = false;
    state.clientCompaniesList.totalCount = resp.totalCount;
    state.clientCompaniesList.items = resp.items;
  },

  [CLIENT_COMPANY_DETAILS_REQUEST]: (state) => {
    state.clientCompanyDetails.loading = !state.clientCompanyDetails.loading;
  },
  [CLIENT_COMPANY_DETAILS_SUCCESS]: (state, resp) => {
    state.clientCompanyDetails.loading = false;
    state.clientCompanyDetails.company = resp;
    state.clientCompanyDetails.company.insuranceCompanyId = state.clientCompanyDetails.company.insuranceCompany.id;
  },

  [CLIENT_COMPANY_SAVE_REQUEST]: (state) => {
    state.clientCompanyDetails.saveLoading = !state.clientCompanyDetails.saveLoading;
  },
  [CHANGE_CLIENT_COMPANIES_FILTER_FOR_PROGRAM]: (state, data) => {
    state.filters = new FiltersDefault();

    if (typeof data === 'string') {
      state.filters.insuranceCompanyIds.push(data);
    } else {
      state.filters.insuranceCompanyIds = data;
    }
  },
  [CLIENT_COMPANIES_CLEAR]: (state) => {
    state.clientCompaniesList.items = [];
  },
  [CHANGE_CLIENT_COMPANIES_SEARCH_QUERY_CHANGE]: (state, query) => {
    state.clientCompaniesSearchQuery = query;
    localStorage.setItem('clientCompaniesSearchQuery', query);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
