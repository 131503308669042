<template>
  <div class="no-results-placeholder">
    <img :src="img" class="no-results-placeholder__img" alt="picture"/>
    <h2 class="h2 color--black-purple mb_16 mt_24">{{ title || $t('adminTitles.nothingHereYet') }}</h2>
    <p class="body-text color--dark-purple no-results-placeholder__description">{{ description || $t('adminTitles.nothingHereYetText')}}</p>
  </div>
</template>

<script>
export default {
  name: 'NoResultsPlaceholder',
  props: {
    title: {
      type: String,
      default: '',
      required: false,
    },
    description: {
      type: String,
      default: '',
      required: false,
    },
  },
  computed: {
    img() {
      return `/img/white-labels/${window.whiteLabelCompany}/ufo.svg`;
    }
  }
};
</script>

<style lang="scss" scoped>
.no-results-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 120px;
}
.no-results-placeholder__img{
  width: 172px;
  height: 172px;
  object-fit: contain;
}
.have-border {
  border: 1px solid var(--solid-middle-purple);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.02);
  border-radius: 8px;
}
.no-results-placeholder__description{
  width: 311px;
  text-align: center;
  line-height: 20px;
}
</style>
