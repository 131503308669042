<template>
  <BaseOverlay
    v-if="user && onboarding && !onboarding.complete && onboardingInsuranceCompany && isUserAdmin"
    class="onboarding__wrapper"
  >
    <div class="onboarding">
      <div class="onboarding__steps">
        <p class="onboarding__steps-title">
          {{ $t('onboarding.WelcomeTo') }} <br>Renova, {{ user.firstName || '' }} {{
            user.lastName || ''
          }}
        </p>
        <p class="body-text color--white mb_28">
          {{ $t('onboarding.ItOnlyTakes3Steps') }}
        </p>
        <OnboardingSteps
          :steps="stepsList"
          :current-step-index="currentStepIndex"
          :all-steps-completed="onboarding.currentStep === stepTypes.ONBOARDING_SUBMIT"
        ></OnboardingSteps>
        <OnboardingPreviewImg
          :current-step="onboarding.currentStep"
        ></OnboardingPreviewImg>
      </div>
      <div class="onboarding__step">
        <div class="onboarding__step-header">
          <div>
            <BaseTextButton
              v-if="currentStepIndex !== 0"
              @click="stepBack()"
              class="color--dark-purple">
              <img src="/img/renova-dashboard/icons/arrow-left.svg" alt="back">
              {{ $t('onboarding.Back') }}
            </BaseTextButton>
          </div>

          <LangSwitcher :reload-app="false"></LangSwitcher>
        </div>
        <component
          v-if="stepInfo.component"
          :is="stepInfo.component"
          :btn-loading="btnLoading"
          @skip="stepForward"
          @submitStep="submitStep"
        />
      </div>
    </div>
  </BaseOverlay>
</template>

<script>
import { mapGetters } from 'vuex';
import BaseOverlay from '@/views/RenovaDashboard/components/ui-kit/BaseOverlay';
import {
  stepsInfo,
  stepsList,
  stepTypes,
} from '@/views/RenovaDashboard/OnboardingParts/onboarding-steps';
import OnboardingCompanyStep
  from '@/views/RenovaDashboard/OnboardingParts/components/OnboardingCompanyStep';
import OnboardingOperatorsStep
  from '@/views/RenovaDashboard/OnboardingParts/components/OnboardingOperatorsStep';
import OnboardingIntegrationStep
  from '@/views/RenovaDashboard/OnboardingParts/components/OnboardingIntegrationStep';
import BaseTextButton from '@/views/RenovaDashboard/components/ui-kit/Buttons/BaseTextButton';
import LangSwitcher from '@/views/RenovaDashboard/components/ui-kit/BaseSideModal/LangSwitcher';
import { SAVE_ONBOARDING, UPDATE_ONBOARDING } from '@/store/actions/onboarding';
import OnboardingSteps
  from '@/views/RenovaDashboard/OnboardingParts/components/OnboardingSteps';
import OnboardingPreviewImg
  from '@/views/RenovaDashboard/OnboardingParts/components/OnboardingPreviewImg';
import OnboardingSubmitStep
  from '@/views/RenovaDashboard/OnboardingParts/components/OnboardingSubmitStep';
import { USER_ROLES } from '@/store/modules/user';

export default {
  name: 'OnboardingComponent',
  components: {
    OnboardingPreviewImg,
    OnboardingSteps,
    LangSwitcher,
    BaseTextButton,
    BaseOverlay,
    OnboardingCompanyStep,
    OnboardingOperatorsStep,
    OnboardingIntegrationStep,
    OnboardingSubmitStep,
  },
  computed: {
    ...mapGetters(['user', 'userRole', 'onboarding', 'onboardingInsuranceCompany']),
    stepInfo() {
      return this.onboarding.currentStep ? stepsInfo[this.onboarding.currentStep] : null;
    },
    currentStepIndex() {
      return stepsList.findIndex((step) => step === this.onboarding.currentStep);
    },
    isUserAdmin() {
      return this.userRole === USER_ROLES.ADMIN;
    },
  },
  data() {
    return {
      stepsList,
      stepsInfo,
      stepTypes,
      btnLoading: false,
    };
  },
  mounted() {
  },
  methods: {
    completeOnboarding() {
      localStorage.setItem('onboardingComplete', 'true');
      this.onboardingComplete = true;
    },
    stepBack() {
      if (this.currentStepIndex === stepsList.length) {
        localStorage.removeItem('onboardingComplete');
      } else {
        this.changeCurrentStep(this.currentStepIndex - 1);
      }
    },
    stepForward() {
      if (this.currentStepIndex === (stepsList.length - 1)) {
        this.completeOnboarding();
      } else {
        this.changeCurrentStep(this.currentStepIndex + 1);
      }
    },
    changeCurrentStep(step) {
      const currentStep = stepsList[step];
      this.$store.dispatch(UPDATE_ONBOARDING, {currentStep});
      this.$store.dispatch(SAVE_ONBOARDING);
    },
    submitStep() {
      this.btnLoading = true;
      this.$store.dispatch(SAVE_ONBOARDING).then((resp) => {
        this.btnLoading = false;
        this.stepForward();
      });
    }
  },
};
</script>

<style lang="scss">
.onboarding {
  display: flex;
  width: 1000px;
  height: 600px;
  background-color: #FFFFFF;
  border-radius: 28px;
  overflow: hidden;
}

.onboarding__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.onboarding__steps-title {
  font-family: 'Gilroy', sans-serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: -1px;
  text-align: left;
  color: #FFFFFF;
  margin-bottom: 36px;
}

.onboarding__steps {
  display: flex;
  flex-direction: column;
  padding: 80px 80px 0 60px;
  width: 50%;
  height: 100%;
  background-image: url(/public/img/renova-dashboard/onboarding/onboarding-bg.png);
  background-size: cover;
  position: relative;
  overflow: hidden;
}

.onboarding__step {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
}

.onboarding__step-header {
  padding: 20px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
