<template>
  <section></section>
</template>

<script>
import { Centrifuge } from 'centrifuge';
import { mapGetters } from 'vuex';
import { PROGRAMS_LIST_REQUEST } from '@/store/actions/programs';

export default {
  name: 'InsuranceNotifications',
  watch: {
    insuranceCompanies: {
      handler() {
        this.subscribeToChannels();
      },
      deep: true,
    },
  },
  mounted() {
  },
  computed: {
    ...mapGetters([
      'insuranceCompanies',
    ]),
  },
  methods: {
    subscribeToChannels() {
      const centrifuge = new Centrifuge(window.WebsocketUrl, { token: localStorage.getItem('ins-token') });
      const channels = this.insuranceCompanies.map((val) => val.insuranceChannel);
      centrifuge.connect();
      channels.forEach((channelName) => {
        const channel = centrifuge.newSubscription(channelName);
        channel.on('publication', (message) => {
          this.handleNotification(message.data);
        });
        channel.subscribe();
      });
    },
    handleNotification(message) {
      switch (message.type) {
        case 'PROGRAM_SERVICES_GENERATION_COMPLETED':
          this.$notify.success({
            dangerouslyUseHTMLString: true,
            message: `${this.$t('adminTitles.ServicesHaveGenerated', {programId: message.programServicesGenerationCompletedDetails.programId})}`,
            duration: 15000,
          });
          this.$store.dispatch(PROGRAMS_LIST_REQUEST);
          break;
        case 'INSURANCE_CONVERSATION_MESSAGE_SEND_BULK_COMPLETED':
          this.$notify.success({
            message: `${this.$t('adminTitles.MessagesSent', {
              count: `${message.insuranceConversationMessageSendBulkCompletedDetails?.sendSuccessCount}`,
            })}`,
          });
          this.$store.dispatch(PROGRAMS_LIST_REQUEST);
          break;
        default:
          break;
      }
    },
  },
};
</script>
