<template>
  <div class="sidebar">
    <div class="sidebar__inner">
      <div class="sidebar__logo">
        <img
          :src="`/img/renova-dashboard/logo.svg`"
          alt="logo"
          class="cursor__pointer"
          @click="reloadPage"
        />
      </div>
      <div class="sidebar__links">
        <section
          v-for="(link, itemIdx) in topRoutes" :key="itemIdx"
        >
          <SideBarLink
            :link="link"
            :class="link.menuLinkClass"
          ></SideBarLink>
        </section>
        <div class="sidebar__links-bottom">
          <SideBarLink
            v-if="user.email === 'demo@renova.world'"
            :link="startOnboardingLink"
            @linkClick="startOnboarding()"
          ></SideBarLink>
          <SideBarLink :link="startGuideLink"></SideBarLink>
          <section
            v-for="(link, itemIdx) in bottomRoutes" :key="itemIdx"
          >
            <SideBarLink :link="link"></SideBarLink>
          </section>
          <SideBarLink :link="providerLink"></SideBarLink>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { AUTH_LOGOUT } from '@/store/actions/auth';
import SideBarLink from '@/views/RenovaDashboard/CommonParts/SideBar/SideBarLink';
import { CLEAR_ONBOARDING, SAVE_ONBOARDING, UPDATE_ONBOARDING } from '@/store/actions/onboarding';
import { DefaultOnboarding } from '@/store/modules/onboarding';
import { stepTypes } from '@/views/RenovaDashboard/OnboardingParts/onboarding-steps';

export default {
  name: 'TheSideBar',
  components: {SideBarLink},
  computed: {
    ...mapGetters(['user', 'ticketList', 'selectedInsuranceProviderDetails']),
    topRoutes() {
      return this.$router.options.routes.filter((item) => item.meta && item.meta.menu && !item.meta.bottomLink).map((item) => item.meta);
    },
    bottomRoutes() {
      return this.$router.options.routes.filter((item) => item.meta && item.meta.menu && item.meta.bottomLink).map((item) => item.meta);
    },
    providerLink() {
      return {
        text: `${this.selectedInsuranceProviderDetails?.name} (${this.$t('adminTitles.ProvidersProfile').toLowerCase()})`,
        path: `/admin/provider/${this.selectedInsuranceProviderDetails?.id}`,
        renovaDashboardIcon: '/img/renova-dashboard/menu/provider.png',
        renovaDashboardActiveIcon: '/img/renova-dashboard/menu/provider--active.svg',
      };
    },
  },
  data() {
    return {
      startGuideLink: {
        name: 'StartGuide',
        externalLink: 'https://renovaworld.notion.site/Renova-Video-Start-Guide-352f265ee73645bc9fb99081aeab2a06?pvs=4',
        renovaDashboardIcon: '/img/renova-dashboard/menu/Book-open.svg',
      },
      startOnboardingLink: {
        name: 'StartOnboarding',
        customEvent: true,
      },
    };
  },
  methods: {
    logout() {
      this.$store.dispatch(AUTH_LOGOUT).then(() => {
        this.$router.push('/login');
      });
    },
    reloadPage() {
      window.location.reload();
    },
    startOnboarding() {
      this.$store.dispatch(CLEAR_ONBOARDING).then(() => this.reloadPage());
    }
  }
};
</script>

<style lang="scss" scoped>
.sidebar {
  min-width: 240px;
  z-index: 100;
  min-height: 100%;
  padding: 16px;
  background: var(--solid-black-purple);

  &__logo {
    img {
      width: 177px;
    }

    margin-bottom: 16px;
  }

  &__links {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 114px);
    overflow: auto;
  }
}

.sidebar__links-bottom {
  margin-top: auto;
  padding-bottom: 50px;
}
</style>
