import Vue from 'vue';
import i18n from '@/i18n';
import locale from 'element-ui/lib/locale';
import ru from 'element-ui/lib/locale/lang/ru-RU';
import en from 'element-ui/lib/locale/lang/en';
import es from 'element-ui/lib/locale/lang/es';
import pt from 'element-ui/lib/locale/lang/pt';

Vue.prototype.$elementLangs = {
  ru,
  en,
  es,
  pt,
};

if (localStorage.getItem('lang')) {
  i18n.locale = localStorage.getItem('lang');
} else {
  i18n.locale = ['ru', 'en', 'es', 'pt'].includes(window.navigator.language) ? window.navigator.language : 'en';

  localStorage.setItem('lang', i18n.locale);
}

locale.use(Vue.prototype.$elementLangs[i18n.locale]);
