const WhitelabelCompanies = ['capitalcare'];
const WhiteLabelConfig = {
  capitalcare: {
    name: 'Capitalcare',
  },
  renova: {
    name: 'Renova',
  }
};

export const getCompanyName = (domain) => WhitelabelCompanies.find((company) => domain.includes(company)) || 'renova';
export const getWhiteLabelConfig = () => WhiteLabelConfig[window.whiteLabelCompany];
export const getCompanyLogo = () => `/img/white-labels/${window.whiteLabelCompany}/logo-min.svg`;
