import axios from 'axios';

import {
  USERS_LIST_REQUEST,
  USERS_LIST_SUCCESS,
  USERS_LIST_CHUNK,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_REQUEST,
  USERS_LIST_SET_LIMIT,
  USERS_LIST_FILTERS_CHANGE,
  USERS_LIST_SET_PAGE,
  USER_SAVE_SUCCESS,
  USER_SAVE_REQUEST,
  USER_EXCLUDE_FROM_PROGRAM,
  USER_DEACTIVATE_FROM_PROGRAM,
  USER_POLICY_DETAILS_REQUEST,
  USER_POLICY_DETAILS_SUCCESS,
  USERS_LIST_REQUEST_FOR_PROGRAM,
  GET_USERS_SUGGEST_LIST,
  ADD_USERS_TO_PROGRAM_FROM_FILE_REQUEST,
  ADD_USERS_TO_PROGRAM_FROM_FILE_SUCCESS,
  USERS_SEND_WELCOME_EMAIL_REQUEST,
  USERS_SEND_WELCOME_EMAIL_SUCCESS,
  USERS_LIST_FILTERS_CLEAR,
  USER_DETAILS_CLEAR,
  USER_SAVE_POLICY,
  USERS_POLICY_LIST_REQUEST,
  USER_CSAT_SUCCESS,
  USER_CSAT_REQUEST,
  USERS_SEND_BULK_MESSAGES,
  USERS_SEND_BULK_MESSAGES_SUCCESS,
} from '@/store/actions/users';

import i18n from '@/i18n';

function FiltersDefault(programIds, searchQuery, limit) {
  this.page = 1;
  this.limit = limit || 30;
  this.clientCompanyIds = [];
  this.insuranceCompanyIds = [];
  this.programIds = programIds || [];
  this.searchQuery = searchQuery || '';
}

/**
 * Load cached state from local storage if they exist or return default
 * @returns {any|FiltersDefault}
 */
function loadCached(key, defaultValue) {
  const values = JSON.parse(localStorage.getItem(key));
  if (values) {
    return values;
  }
  return defaultValue;
}

const state = {
  userList: {
    items: [],
    userSuggestList: [],
    totalCount: 1,
    loading: false,
  },
  usersPolicyList: [],
  userDetails: {
    user: null,
    policyDetails: null,
    csat: {
      items: [],
      totalCount: 0,
    },
    policyDetailsLoading: false,
    loading: false,
    saveLoading: false,
  },
  addUsersFromFile: {
    errors: [],
    loading: false,
  },
  usersSendEmails: {
    errors: [],
    loading: false,
  },
  filters: loadCached('usersListFilters', new FiltersDefault()),
  // Users which been selected for welcome email sending
  programMultipleSelectionIds: loadCached('programMultipleSelectionIds', []),
};

export function getUserData(user) {
  return axios.post(
    '/insurance/client/detail',
    user,
    {headers: {'X-Locale': i18n.locale}},
  );
}

const getters = {
  userList: () => state.userList,
  usersPolicyList: () => state.usersPolicyList,
  userDetails: () => state.userDetails,
  userListFilters: () => state.filters,
  addUsersFromFile: () => state.addUsersFromFile,
  usersSendEmails: () => state.usersSendEmails,
  programMultipleSelectionIds: () => state.programMultipleSelectionIds,
};

const actions = {
  [USERS_LIST_REQUEST]({commit, dispatch, state}, forProgram = false) {
    const filters = JSON.parse(JSON.stringify(state.filters));
    filters.page -= 1;

    commit(USERS_LIST_REQUEST);
    const postData = filters;
    // Will exclude programIds from filters because it is not needed for this request
    if (!forProgram) {
      delete postData.programIds;
    }
    return axios.post(
      '/insurance/client/list',
      postData,
      {headers: {'X-Locale': i18n.locale}},
    )
      .then((resp) => {
        commit(USERS_LIST_SUCCESS, resp.data);
        if (forProgram) {
          dispatch(USERS_POLICY_LIST_REQUEST, {
            clientCompanyIds: postData.clientCompanyIds,
            personProfileIds: resp.data.items.map((val) => val.personProfileId),
            programIds: postData.programIds,
          });
        }
        return resp.data;
      })
      .catch((err) => {
        commit(USERS_LIST_REQUEST);
        console.log(err.response.data);
        return Promise.reject(err.response.data);
      });
  },
  [USERS_LIST_CHUNK]({commit, dispatch, state}, payload) {
    return axios.post(
      '/insurance/client/list',
      payload,
      {headers: {'X-Locale': i18n.locale}},
    )
      .then((resp) => resp.data)
      .catch((err) => Promise.reject(err.response.data));
  },
  [USER_DETAILS_REQUEST]({commit}, user) {
    commit(USER_DETAILS_REQUEST);
    return getUserData(user).then((resp) => {
      commit(USER_DETAILS_SUCCESS, resp.data);
      return resp.data;
    })
      .catch((err) => {
        commit(USER_DETAILS_REQUEST);
        console.log(err.response.data);
        return Promise.reject(err.response.data);
      });
  },
  [USER_CSAT_REQUEST]({commit}, user) {
    commit(USER_CSAT_REQUEST);
    return axios.post(
      '/insurance/poll/list',
      {
        limit: 100,
        page: 0,
        personProfileIds: [
          user,
        ],
      },
      {headers: {'X-Locale': i18n.locale}},
    )
      .then((resp) => {
        commit(USER_CSAT_SUCCESS, resp.data);
        return resp.data;
      })
      .catch((err) => {
        commit(USER_CSAT_REQUEST);
        console.log(err.response.data);
        return Promise.reject(err.response.data);
      });
  },
  [USER_SAVE_REQUEST]({commit}, user) {
    commit(USER_SAVE_REQUEST);
    return axios.post(
      '/insurance/client/save',
      user,
      {headers: {'X-Locale': i18n.locale}},
    )
      .then((resp) => {
        commit(USER_SAVE_SUCCESS, resp.data);
        return resp.data;
      })
      .catch((err) => {
        commit(USER_SAVE_REQUEST);
        return Promise.reject(err.response.data);
      });
  },
  [USER_EXCLUDE_FROM_PROGRAM]({commit, dispatch}, data) {
    return axios.post('/insurance/client/exclude-from-program', data)
      .then((resp) => {
        if (data.programId) {
          dispatch(USERS_LIST_REQUEST_FOR_PROGRAM, {packageId: data.programId});
        } else {
          dispatch(USER_DETAILS_REQUEST, {personProfileId: data.personProfileId});
        }
        return resp.data;
      })
      .catch((err) => Promise.reject(err.response.data));
  },
  [USER_DEACTIVATE_FROM_PROGRAM]({commit, dispatch}, data) {
    return axios.post('/insurance/policy/disable', {
      disableDate: data.disableDate,
      policyIds: data.policyIds,
      programId: data.programId,
    })
      .then((res) => {
        if (data.programId) {
          dispatch(USERS_LIST_REQUEST_FOR_PROGRAM, {packageId: data.programId});
        } else {
          dispatch(USER_DETAILS_REQUEST, {personProfileId: data.personProfileId});
        }
        return res.data;
      })
      .catch((err) => Promise.reject(err.response.data));
  },
  [GET_USERS_SUGGEST_LIST]({commit}, searchString) {
    return axios.post(
      '/insurance/client/suggest/list',
      {limit: 10, searchString},
      {headers: {'X-Locale': i18n.locale}},
    )
      .then((resp) => {
        commit(GET_USERS_SUGGEST_LIST, resp.data);
        return resp.data;
      })
      .catch((err) => Promise.reject(err.response.data));
  },
  [USER_POLICY_DETAILS_REQUEST]({commit}, policyId) {
    commit(USER_POLICY_DETAILS_REQUEST);
    return axios.post(
      '/insurance/policy/detail',
      policyId,
      {headers: {'X-Locale': i18n.locale}},
    )
      .then((resp) => {
        commit(USER_POLICY_DETAILS_SUCCESS, resp.data);
        return resp.data;
      })
      .catch((err) => {
        commit(USER_POLICY_DETAILS_REQUEST);
        return Promise.reject(err.response.data);
      });
  },
  [ADD_USERS_TO_PROGRAM_FROM_FILE_REQUEST]({commit, dispatch}, data) {
    const formData = new FormData();
    formData.append('file', data.file);
    return axios({
      url: `/insurance/client/batch/upload-with-policies?packageId=${data.packageId}&sendWelcomeEmail=${data.sendWelcomeEmail}&sendWelcomeEmailAt=${data.sendWelcomeEmailAt}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'X-Locale': i18n.locale,
      },
      data: formData,
      method: 'POST',
    })
      .then((resp) => {
        commit(ADD_USERS_TO_PROGRAM_FROM_FILE_SUCCESS, resp.data);
        dispatch(USERS_LIST_REQUEST_FOR_PROGRAM, {packageId: data.packageId, searchQuery: ''});
        return resp.data;
      })
      .catch((err) => {
        commit(ADD_USERS_TO_PROGRAM_FROM_FILE_REQUEST);
        return Promise.reject(err.response.data);
      });
  },
  [USERS_SEND_WELCOME_EMAIL_REQUEST]({commit, dispatch}, data) {
    return axios.post('/insurance/client/welcome-email/send', data)
      .then((resp) => {
        commit(USERS_SEND_WELCOME_EMAIL_SUCCESS, resp.data);
        dispatch(USERS_LIST_REQUEST_FOR_PROGRAM, {packageId: data.programId, searchQuery: ''});
        return resp.data;
      })
      .catch((err) => {
        commit(ADD_USERS_TO_PROGRAM_FROM_FILE_REQUEST);
        return Promise.reject(err.response.data);
      });
  },
  [USERS_SEND_BULK_MESSAGES]({commit, dispatch}, data) {
    return axios.post('/insurance/conversation/message/send-bulk', data)
      .then((resp) => {
        commit(USERS_SEND_BULK_MESSAGES_SUCCESS, resp.data);
        return resp.data;
      })
      .catch((err) => Promise.reject(err.response.data));
  },
  [USERS_LIST_FILTERS_CHANGE]({commit, dispatch}, filters) {
    commit(USERS_LIST_FILTERS_CHANGE, filters);
    dispatch(USERS_LIST_REQUEST);
  },
  [USERS_LIST_SET_LIMIT]({commit, dispatch}, limit) {
    commit(USERS_LIST_SET_LIMIT, limit);
    dispatch(USERS_LIST_REQUEST);
  },
  [USERS_LIST_SET_PAGE]({commit, dispatch}, page) {
    commit(USERS_LIST_SET_PAGE, page);
    dispatch(USERS_LIST_REQUEST);
  },
  [USERS_LIST_REQUEST_FOR_PROGRAM]({commit, dispatch}, params) {
    commit(USERS_LIST_REQUEST_FOR_PROGRAM, params);
    dispatch(USERS_LIST_REQUEST, true);
  },

  [USER_SAVE_POLICY]({commit}, policy) {
    return axios.post(
      '/insurance/policy/save',
      policy,
      {headers: {'X-Locale': i18n.locale}},
    )
      .then((resp) => resp.data)
      .catch((err) => Promise.reject(err.response.data));
  },
  [USERS_POLICY_LIST_REQUEST]({commit, state}, data) {
    return axios.post(
      '/insurance/policy/list',
      data,
    ).then((resp) => {
      state.usersPolicyList = resp.data.items;
      return resp.data;
    })
      .catch((err) => {
        console.log(err.response.data);
        return Promise.reject(err.response.data);
      });
  },
};
// ///////////////////////////////////////////////////////////////////////////////////
const mutations = {
  [USERS_LIST_REQUEST]: (state) => {
    state.userList.loading = !state.userList.loading;
  },
  [USERS_LIST_SUCCESS]: (state, resp) => {
    state.userList.loading = false;
    state.userList.items = resp.items;
    state.userList.totalCount = resp.totalCount;
  },
  [USER_DETAILS_REQUEST]: (state) => {
    state.userDetails.user = null;
    state.userDetails.loading = !state.userDetails.loading;
  },
  [USER_DETAILS_SUCCESS]: (state, resp) => {
    state.userDetails.loading = false;
    state.userDetails.user = resp;

    state.userDetails.user.programs = [];

    state.userDetails.user.policies.forEach((policy) => {
      state.userDetails.user.programs.push({
        ...policy.program,
        validity: policy.validity,
      });
    });
  },
  [USER_CSAT_SUCCESS]: (state, resp) => {
    state.userDetails.csat = resp;
  },
  [USER_DETAILS_CLEAR]: (state) => {
    state.userDetails.user = null;
  },

  [USER_SAVE_REQUEST]: (state) => {
    state.userDetails.saveLoading = !state.userDetails.saveLoading;
  },
  [USER_SAVE_SUCCESS]: (state, resp) => {
    state.userDetails.saveLoading = false;
    state.userDetails.user = resp;

    state.userDetails.user.programs = [];

    state.userDetails.user.policies.forEach((item) => {
      state.userDetails.user.programs.push(item.program);
    });
  },
  [USERS_LIST_FILTERS_CHANGE]: (state, filters) => {
    state.filters = filters;
    localStorage.setItem('usersListFilters', JSON.stringify(filters));
  },
  [USERS_LIST_FILTERS_CLEAR]: (state) => {
    state.filters = new FiltersDefault();
  },
  [USERS_LIST_SET_PAGE]: (state, page) => {
    state.filters.page = page;
  },
  [USERS_LIST_SET_LIMIT]: (state, limit) => {
    state.filters.limit = limit;
    state.filters.page = 1;
  },
  [USER_POLICY_DETAILS_REQUEST]: (state) => {
    state.userDetails.policyDetailsLoading = !state.userDetails.policyDetailsLoading;
  },
  [USER_POLICY_DETAILS_SUCCESS]: (state, resp) => {
    state.userDetails.policyDetailsLoading = false;
    state.userDetails.policyDetails = resp;
  },
  [USERS_LIST_REQUEST_FOR_PROGRAM]: (state, params) => {
    // If searchQuery is not set, then use the one from the state to keep it
    const searchQuery = params.searchQuery ?? state.filters.searchQuery;
    state.filters = new FiltersDefault([params.packageId], searchQuery, 10000);
    // as long as filters changed will remember filters  in local storage
    localStorage.setItem('usersListFilters', JSON.stringify(state.filters));
  },
  [GET_USERS_SUGGEST_LIST]: (state, resp) => {
    state.userList.userSuggestList = resp.items;
  },
  [ADD_USERS_TO_PROGRAM_FROM_FILE_REQUEST]: (state) => {
    state.addUsersFromFile.loading = !state.addUsersFromFile.loading;
    state.addUsersFromFile.errors = [];
  },
  [ADD_USERS_TO_PROGRAM_FROM_FILE_SUCCESS]: (state, resp) => {
    state.addUsersFromFile.loading = false;
    state.addUsersFromFile.errors = resp.errors;
  },
  [USERS_SEND_WELCOME_EMAIL_REQUEST]: (state) => {
    state.usersSendEmails.loading = !state.usersSendEmails.loading;
    state.usersSendEmails.errors = [];
  },
  [USERS_SEND_WELCOME_EMAIL_SUCCESS]: (state, resp) => {
    state.usersSendEmails.loading = false;
    state.usersSendEmails.errors = resp.errors;
  },
  [USERS_SEND_BULK_MESSAGES_SUCCESS]: (state, resp) => {
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
