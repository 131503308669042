<template>
  <div
    :class="`simple-dropdown ${isOpen ? 'simple-dropdown--open' : ''} simple-dropdown--${position}`"
    ref="dropdown"
  >
    <div
      v-if="!dotsBtn"
      @click="openOnArrow ? $emit('btnClick') : toggleDropdown()"
      class="simple-dropdown__button body-text color--dark-purple"
      :class="{'simple-dropdown__button--clearable' : clearable && selectedOptionValue}"
    >
      <slot name="optionButton">
        <img v-if="selectedOptionValue && selectedOptionValue.optionLogo"
             class="simple-dropdown__logo" :src="selectedOptionValue.optionLogo" alt="logo"/>
        <span>{{
            buttonText ? buttonText : (selectedOptionValue ? (selectedOptionValue.shortText || selectedOptionValue.text || selectedOptionValue.name) : '')
          }}</span>
        <i
          v-if="showArrow"
          class="el-icon-arrow-down color--dark-purple"
          @click="openOnArrow ? toggleDropdown($event) : null"
        ></i>
        <i
          v-if="clearable ? selectedOption : false"
          class="el-icon-close color--dark-purple"
          @click="resetOption"
        ></i>
      </slot>
    </div>
    <div
      v-if="dotsBtn"
      @click="toggleDropdown"
      class="simple-dropdown__button simple-dropdown__button--dots">
    </div>
    <div
      v-show="isOpen"
      class="simple-dropdown__options"
      :style="{
        top: offsetTop ? (offsetTop + 'px') : 'auto',
        left: offsetLeft ? (offsetLeft + 'px'): 'auto',
        minWidth: minWidth ? minWidth : null
      }"
    >
      <div
        v-for="option in options"
        :key="option.id"
        class="simple-dropdown__option body-text"
        :class="{'simple-dropdown__option--active': selectedOptionValue && selectedOptionValue.id === option.id}"
        @click="selectOption(option)">
        <img class="simple-dropdown__logo" v-if="option.optionLogo" :src="option.optionLogo"
             alt="logo"/>
        {{ option.text || option.name || option.title }}
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'BaseSimpleDropdown',
  emits: ['selectOption', 'btnClick'],
  props: {
    options: {
      type: Array,
      required: true,
    },
    position: {
      type: String,
      default: 'bottom',
      validate: (value) => ['bottom', 'bottom-left', 'top'].includes(value),
    },
    selectedOption: {
      type: String,
      default: null,
    },
    buttonText: {
      type: String,
      default: '',
    },
    returnFullOption: {
      type: Boolean,
      default: false,
    },
    dotsBtn: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    openOnArrow: {
      type: Boolean,
      default: false,
    },
    showArrow: {
      type: Boolean,
      default: true,
    },
    id: {
      type: String,
      default: '',
    },
    offsetTop: {
      type: Number,
      default: 40,
    },
    offsetLeft: {
      type: Number,
      default: null,
    },
    minWidth: {
      type: String,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    selectedOptionValue() {
      return this.selectedOption
        ? this.options.find((val) => val.id === this.selectedOption) || null
        : null;
    },
  },
  methods: {
    toggleDropdown(event) {
      if (event) {
        event.stopPropagation();
      }
      this.isOpen = !this.isOpen;
    },
    selectOption(option) {
      if (option.id !== this.selectedOption) {
        this.$emit('selectOption', this.returnFullOption ? option : option.id);
      } else {
        this.$emit('selectOption', null);
      }
      this.isOpen = false;
    },
    resetOption() {
      this.$emit('selectOption', null);
      this.isOpen = false;
    },
    onClickOutside(event) {
      if (this.isOpen && this.$refs.dropdown && !this.$refs.dropdown.contains(event.target)) {
        this.isOpen = false;
      }
    },
  },
  created() {
    document.addEventListener('click', this.onClickOutside);
  },
  destroyed() {
    document.removeEventListener('click', this.onClickOutside);
  },
};
</script>

<style lang="scss">
.simple-dropdown {
  position: relative;

  &--open {
    z-index: 1000;

    .simple-dropdown__button {
      i {
        transform: rotate(180deg);
      }
    }
  }

  &--bottom {
    .simple-dropdown__options {
      top: 40px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &--bottom-left {
    .simple-dropdown__options {
      top: 40px;
      right: 0;
      left: auto;
    }
  }
}

.simple-dropdown__button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
  cursor: pointer;
  position: relative;

  i {
    transition: .3s;
  }

  .el-icon-close {
    transform: rotate(0) !important;
    display: none;
  }

  &--dots {
    width: 16px;
    height: 16px;
    background-image: url(/public/img/renova-dashboard/icons/menu-dots.svg);
    background-size: contain;
  }
}

.simple-dropdown__button--clearable:hover {
  .el-icon-close {
    display: flex;
  }

  .el-icon-arrow-down {
    display: none;
  }
}

.simple-dropdown__options {
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: absolute;
  background: #fff;
  border: 1px solid var(--solid-lavender-grey);
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(24, 7, 38, 0.15);
}

.simple-dropdown__option {
  border: none;
  background: #fff;
  padding: 8px;
  color: var(--solid-black-purple);
  text-align: start;
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;
  transition: .3s;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 8px;

  &:hover {
    background: var(--solid-grey-light);
  }

  &--active {
    color: var(--solid-purple) !important;
  }
}

.simple-dropdown__logo {
  width: 24px;
  height: 24px;
  object-fit: cover;
  border-radius: 4px;
}
</style>
