/* eslint-disable no-param-reassign */

import axios from 'axios';
import i18n from '@/i18n';
import sortLanguages from '@/services/sortLanguages';
import {
  GET_PHONE_CODES, SET_PHONE_CODE, GET_DICTIONARY, GET_HR_DICTIONARY,
  GET_CITIES, GET_COUNTRIES, CITIES_FILTERS_CHANGE, GET_CITIES_CSV,
} from '../actions/dictionary';

const setDefaultIcons = (list) => list.map((val) => {
  if (val.phoneRegion === 'CY') {
    return { ...val, flagIconWeb: 'https://350809.selcdn.ru/carefy-images/flag/web_cy.svg' };
  }
  return val;
});
const state = {
  ticketStatuses: [],
  ticketBusinessTypes: [],
  insuranceCompanies: [],
  offerStatuses: [],
  offerTypes: [],
  languages: [],
  userDoctorCallStatuses: [],
  userReceptionStatuses: [],
  ticketSourceTypeStatuses: [],
  clientCompanies: [],
  phoneCodes: {
    codes: [],
    selectedCountry: {
      code: '%2B33',
      flagIcon: 'https://350809.selcdn.ru/carefy-images/flag/mob_fr.png',
      flagIconWeb: 'https://350809.selcdn.ru/carefy-images/flag/web_fr.svg',
      id: '',
      name: 'Франция',
      phoneCode: '+33',
      phoneMobileMask: '###-##-##-##',
      phoneLength: 9,
      phoneRegion: 'FR',
    },
  },
  loading: false,
  hrDictionary: [],
  hrLoading: false,
  cities: {
    items: [],
    filters: {
      page: 1,
      limit: 30,
      withPaging: true,
      countryIds: [],
      searchQuery: '',
    },
    totalCount: 1,
    loading: false,
  },
  countries: {
    items: [],
    filters: {
      withPaging: false,
    },
    loading: false,
  },
};

const getters = {
  ticketStatuses: () => state.ticketStatuses,
  ticketBusinessTypes: () => state.ticketBusinessTypes,
  insuranceCompanies: () => state.insuranceCompanies,
  phoneCodes: () => state.phoneCodes,
  offerStatuses: () => state.offerStatuses,
  offerTypes: () => state.offerTypes,
  languages: () => state.languages,
  userDoctorCallStatuses: () => state.userDoctorCallStatuses,
  userReceptionStatuses: () => state.userReceptionStatuses,
  ticketSourceTypeStatuses: () => state.ticketSourceTypeStatuses,
  clientCompanies: () => state.clientCompanies,
  dictionaryLoading: () => state.loading,

  hrDictionary: () => state.hrDictionary,
  dictionaryHrLoading: () => state.hrLoading,

  cities: () => state.cities,
  countries: () => state.countries,

  /**
   * Filter insurance companies by selected client companies
   * @param state
   * @param_of_returned_function CompanyIds {Array} - array of client company ids to filter insurance companies
   * @returns {function(Array): *}
   */
  filteredInsuranceCompaniesByCompanyIds: (state) => (companyIds) => state.clientCompanies
    .reduce((acc, item) => {
      if (companyIds.includes(item.id)) {
        // To prevent duplicates will check if it is already in the array
        if (!acc.find((el) => el.id === item.insuranceCompany.id)) {
          acc.push(item.insuranceCompany);
        }
      }
      return acc;
    }, []),
};

const actions = {
  [GET_DICTIONARY]({ commit }, userId) {
    commit(GET_DICTIONARY, false);
    return axios.post('/insurance/dictionary/details', {}, { headers: { 'X-Locale': i18n.locale } })
      .then((resp) => {
        commit(GET_DICTIONARY, {
          data: resp.data,
          userId,
        });
        return Promise.resolve();
      })
      .catch((err) => {
        commit(GET_DICTIONARY, false);
        return Promise.reject(err.response.data);
      });
  },
  [GET_HR_DICTIONARY]({ commit }) {
    commit(GET_HR_DICTIONARY, false);

    return axios.post('/company/dictionary/details', {}, { headers: { 'X-Locale': i18n.locale } })
      .then((resp) => {
        commit(GET_HR_DICTIONARY, resp.data);
        return Promise.resolve();
      })
      .catch((err) => {
        commit(GET_HR_DICTIONARY, false);
        return Promise.reject(err.response.data);
      });
  },
  [GET_PHONE_CODES]({ commit }) {
    return axios.get('/dicts/country-phone-code/list', { headers: { 'X-Locale': i18n.locale } })
      .then((resp) => {
        commit(GET_PHONE_CODES, setDefaultIcons(resp.data));
        return Promise.resolve();
      })
      .catch(() => Promise.reject());
  },

  [GET_CITIES]({ commit, state }, payload) {
    commit(GET_CITIES);

    const filters = JSON.parse(JSON.stringify(state.cities.filters));
    filters.page -= 1;

    return axios.post(
      '/insurance/city/list',
      {
        ...filters,
        withPaging: payload && payload.withPaging ? payload.withPaging : false,
      },
      {
        headers: {
          'X-Locale': i18n.locale,
        },
      },
    )
      .then((resp) => {
        commit(GET_CITIES, resp.data);
        return Promise.resolve();
      })
      .catch((err) => {
        commit(GET_CITIES);
        return Promise.reject(err.response.data);
      });
  },
  [CITIES_FILTERS_CHANGE]({ commit, dispatch }, filters) {
    commit(CITIES_FILTERS_CHANGE, filters);
    dispatch(GET_CITIES);
  },
  [GET_CITIES_CSV]({ commit, state }) {
    const params = {
      withPaging: false,
      countryIds: state.cities.filters.countryIds,
    };

    return axios.post(
      '/insurance/city/list-in-csv',
      params,
      {
        responseType: 'arraybuffer',
      },
    )
      .then((resp) => resp.data)
      .catch((err) => {
        commit(GET_CITIES);
        return Promise.reject(err.response.data);
      });
  },

  [GET_COUNTRIES]({ commit, state }) {
    commit(GET_COUNTRIES);

    return axios.post(
      '/insurance/country/list',
      state.countries.filters,
      { headers: { 'X-Locale': i18n.locale } },
    )
      .then((resp) => {
        commit(GET_COUNTRIES, resp.data);
        return Promise.resolve();
      })
      .catch((err) => {
        commit(GET_COUNTRIES);
        return Promise.reject(err.response.data);
      });
  },
};

const mutations = {
  [GET_DICTIONARY]: (state, resp) => {
    const { data, userId } = resp;
    if (!data) {
      state.loading = !state.loading;
    } else {
      state.ticketStatuses = data.ticketStatuses;
      state.ticketBusinessTypes = data.ticketBusinessTypes.filter((type) => type.id !== 'LEAD');
      state.insuranceCompanies = data.insuranceCompanies;
      state.offerStatuses = data.offerStatuses;
      state.offerTypes = data.offerTypes;
      state.userDoctorCallStatuses = data.userDoctorCallStatuses;
      state.userReceptionStatuses = data.userReceptionStatuses;
      state.ticketSourceTypeStatuses = data.ticketSourceTypeStatuses;
      state.clientCompanies = data.clientCompanies;
      state.languages = sortLanguages(data.languages);
      state.loading = false;
    }
  },
  [GET_HR_DICTIONARY]: (state, resp) => {
    if (!resp) {
      state.hrLoading = !state.hrLoading;
    } else {
      state.hrDictionary = resp;
      state.hrLoading = false;
    }
  },
  [GET_PHONE_CODES]: (state, resp) => {
    state.phoneCodes.codes = resp;

    state.phoneCodes.codes.forEach((item) => {
      item.code = item.phoneCode.replace('+', '%2B');
      const re1 = /[[\]]/g;
      const re2 = /0/g;
      const re3 = /[^+#]/g;
      item.phoneMobileMask = item.phoneMobileMask.replace(re1, '').replace(re2, '#');
      item.phoneLength = item.phoneMobileMask.replace(re3, '').length;
      item.phoneMasks = item.phoneMasks.map((mask) => mask.replace(re1, '').replace(re2, '#'));
      item.phoneLengthes = item.phoneMasks.map((mask) => mask.replace(re1, '').replace(re2, '#').replace(re3, '').length);
    });
  },
  [SET_PHONE_CODE]: (state, phoneCode) => {
    state.phoneCodes.selectedCountry = phoneCode;
  },

  [GET_CITIES]: (state, resp) => {
    if (!resp) {
      state.cities.loading = !state.cities.loading;
    } else {
      state.cities.items = resp.items;
      state.cities.totalCount = resp.totalCount;
      state.cities.loading = false;
    }
  },
  [CITIES_FILTERS_CHANGE]: (state, filters) => {
    state.cities.filters = filters;
  },

  [GET_COUNTRIES]: (state, resp) => {
    if (!resp) {
      state.countries.loading = !state.countries.loading;
    } else {
      state.countries.items = resp.items;
      state.countries.loading = false;
    }
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
