import axios from 'axios';

const WhatsAppIntegration = {
  methods: {
    setUpInterval() {
      this.interval = setInterval(() => {
        const whatsAppStatus = this.insuranceConfig?.whatsAppUnofficialDetailsView?.status;
        if (whatsAppStatus && (whatsAppStatus === 'CREATING' || whatsAppStatus === 'QR_CONFIRMATION')) {
          this.fetchWhatsAppData();
          this.$emit('WhatsAppConnected');
        }
      }, 3000);
      if (!this.insuranceConfig) {
        this.fetchWhatsAppData();
      }
    },
    fetchWhatsAppData() {
      axios
        .post('/insurance/config/detail', {
          insuranceCompanyId: this.selectedInsuranceProvider,
        })
        .then((response) => {
          this.insuranceConfig = response.data;
          if (this.insuranceConfig?.whatsAppUnofficialDetailsView?.status === 'CONNECTED') {
            this.onWhatsUpConnect();
          }
        })
        .catch((error) => {
          console.error('Error sending POST request:', error);
        });
    },
    disconnectWhatsAppRequest() {
      this.btnLoading = true;
      return axios.post('insurance/config/save', {
        insuranceCompanyId: this.selectedInsuranceProvider,
        whatsAppUnofficialConnectionDelete: true,
      }).then(() => {
        this.$notify.success(this.$t('employees.WhatsAppWasSuccessfullyDisconnected'));
        this.$emit('close');
      });
    },
    clearInterval() {
      clearInterval(this.interval);
    }
  },
  destroyed() {
    this.clearInterval(this.interval);
  },
};

export default WhatsAppIntegration;
