<template>
  <div class="admin-navbar">
    <div class="admin-navbar__left">
      <a href="/admin/dashboard">
        {{ $t('topBar.main') }}
        <img
          v-if="breadcrumbs.length"
          class="chevron-right"
          src="/img/renova-dashboard/chevron-left.svg"
          alt=">"
        />
      </a>
      <a
        v-for="(item, index) in breadcrumbs"
        :key="item.link + item.title"
        :href="breadcrumbs.length - 1 !== index ? item.link : '#'"
      >
        {{ item.title }}
        <img
          v-if="breadcrumbs.length - 1 !== index"
          class="chevron-right"
          src="/img/renova-dashboard/chevron-left.svg"
          alt=">"
        />
      </a>
    </div>
    <div class="admin-navbar__right">
      <LangSwitcher></LangSwitcher>
      <a href="#" @click="showLogoutConfirm = true">
        {{ $t('adminTitles.logout') }}
      </a>
    </div>
    <BaseDialog
      v-if="showLogoutConfirm"
      title=""
      :text="$t('adminTitles.logoutConfirm')"
      :confirm-button-text="$t('adminTitles.logout')"
      :cancel-button-text="$t('buttons.cancel')"
      @close="showLogoutConfirm = false"
      @confirm="logout"
    />
  </div>
</template>

<script>
import { AUTH_LOGOUT } from '@/store/actions/auth';
import { mapGetters } from 'vuex';
import BaseDialog from '@/views/RenovaDashboard/components/ui-kit/BaseDialog';
import BaseSimpleDropdown
  from '@/views/RenovaDashboard/components/ui-kit/Dropdowns/BaseSimpleDropdown';
import axios from 'axios';
import LangSwitcher from '@/views/RenovaDashboard/components/ui-kit/BaseSideModal/LangSwitcher';

export default {
  name: 'AdminNavBar',
  components: {
    BaseDialog,
    LangSwitcher,
  },
  props: {
    breadcrumbs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showLogoutConfirm: false,
    };
  },
  watch: {},
  computed: {
    ...mapGetters(['user', 'insuranceProviders', 'selectedInsuranceProviderDetails']),
  },
  created() {
    this.currentLang = localStorage.getItem('lang');
  },
  methods: {
    logout() {
      this.$store.dispatch(AUTH_LOGOUT)
        .then(() => {
          this.$router.push('/login');
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.admin-navbar {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid var(--solid-lavender-grey);
  padding: 0px 48px;
  height: 56px;

  a {
    color: var(--solid-dark-purple);
    display: inline-flex;
    gap: 8px;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }

  &__left {
    a {
      margin-right: 8px;
    }
  }

  .chevron-right {
    transform: rotate(180deg);
  }

  &__language-dropdown {
    display: inline-block;
    position: relative;

    img {
      transform: rotate(270deg);
    }

    &--open {
      img {
        transform: rotate(90deg);
      }
    }
  }

  &__language-dropdown-menu {
    bottom: -120px;
    z-index: 100;
  }
}

.admin-navbar__right {
  display: flex;
  align-items: center;
  gap: 24px;
}
</style>
