<template>
  <div class="login-form__container">
    <p
      class="h2 login-form__container-title color--black-purple"
      v-if="title"
      :class="{'mb_0' : !text}"
    >{{ title }}</p>
    <p class="login-form__container-text body-text color--black-purple" v-if="text">{{ text }}</p>
    <div class="login-form__container-content" v-if="showContent">
      <slot/>
    </div>
    <div
      class="login-form__container-btn"
      :class="{'login-form__container-btn--centered': centeredBtn}"
    >
      <BaseButton
        @click="onClick"
        :loading="btnLoading">
        {{ btnText }}
      </BaseButton>
    </div>
    <p
      v-if="showPolicyText"
      class="login-form__container-privacy body-text"
      v-html="$t('adminTitles.ByClickingOnThisButtonYouAccept')">
    </p>
    <span
      class="login-form__container-back body-text color--purple"
      v-if="backBtnText"
      @click="$emit('backClick')"
    >
      <img src="/img/renova-dashboard/icons/arrow-left--purple.svg" alt="back-btn">{{ backBtnText }}
    </span>
  </div>
</template>

<script>
import BaseButton from '@/views/RenovaDashboard/components/ui-kit/Buttons/BaseButton';

export default {
  name: 'LoginFormContainer',
  components: {
    BaseButton,
  },
  emits: ['btnClick', 'backClick'],
  props: {
    title: {
      type: String,
    },
    text: {
      type: String,
    },
    btnText: {
      type: String,
    },
    btnLoading: {
      type: Boolean,
    },
    centeredBtn: {
      type: Boolean,
    },
    backBtnText: {
      type: String,
      default: null,
    },
    showPolicyText: {
      type: Boolean,
      default: true,
    },
    showContent: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    onClick() {
      this.$emit('btnClick');
    },
  },
};
</script>

<style lang="scss">
.login-form__container {
  width: 416px;
  padding: 40px 28px;
  border-radius: 16px;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
}

.login-form__container-title {
  text-align: center;
  margin-bottom: 12px;
}

.login-form__container-text {
  text-align: center;
  padding: 0 16px;
  margin-bottom: 8px;
  line-height: 20px;
}

.login-form__container-content {
  margin-top: 32px;
}

.login-form__container-btn {
  margin-top: 20px;

  .base-button {
    justify-content: center;
    width: 100%;
  }
  &--centered{
    display: flex;
    justify-content: center;
    .base-button{
      width: auto;
    }
  }
}

.login-form__container-privacy {
  margin-top: 20px;
  padding: 0 23px;
  text-align: center;
  color: var(--solid-black-purple);

  a {
    color: var(--solid-purple) !important;
  }
}

.login-form__container-back {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 28px;
  cursor: pointer;
  img{
    width: 11px;
  }
}
</style>
