<template>
  <div class="onboarding-operators">
    <p class="h2 mb_12 color--black-purple text_ac">
      {{ $t('onboarding.AddEmailsOfColleaguesHandling') }}
    </p>
    <p class="body-text mb_32 onboarding-operators__description"
       v-html="$t('onboarding.WeWillSendThemInvites')">
    </p>
    <p class="onboarding-operators__caption caption color--dark-purple mb_8">{{$t('onboarding.OperatorsEmails')}}</p>
    <el-form
      ref="form"
      :model="form"
      class="onboarding-operators__form"
      @submit.native.prevent
    >
      <el-form-item
        v-for="(operator, index) in form.operators"
        :id="`Operator_${operator.key}`"
        class="onboarding-operators__form-input"
        :key="operator.key"
        :prop="'operators.' + index + '.value'"
        :rules="emailRules"
      >
        <el-input
          v-model="operator.value"
          :placeholder="$t('adminTitles.EnterEmail')"
        />
        <BaseTextButton
          @click="removeOperator(operator)"
          class="onboarding-operators__form-input-btn color--dark-purple"
          v-if="form.operators.length > 1 && !!operator.value || index > 0"
        >
          {{ $t('adminTitles.Delete') }}
        </BaseTextButton>
      </el-form-item>
      <BaseTextButton
        @click="addOperator"
        class="color--black-purple mt_12">
        <img src="/img/renova-dashboard/icons/plus-circle.svg" alt="add">
        {{ $t('onboarding.AddOperator') }}
      </BaseTextButton>
    </el-form>
    <BaseButton
      :loading="btnLoading"
      :full-width="true"
      class="mt_32 mb_16"
      @click="submitStep"
    >
      {{ $t('buttons.add') }}
    </BaseButton>
    <BaseTextButton
      @click="skipStep"
      class="color--purple">
      {{ $t('onboarding.SkipForNow') }}
    </BaseTextButton>
  </div>
</template>

<script>
import BaseTextButton from '@/views/RenovaDashboard/components/ui-kit/Buttons/BaseTextButton';
import { mapGetters } from 'vuex';
import BaseButton from '@/views/RenovaDashboard/components/ui-kit/Buttons/BaseButton';
import { UPDATE_ONBOARDING } from '@/store/actions/onboarding';

const defaultOperatorsList = [{
  key: 0,
  value: ''
}];
export default {
  name: 'OnboardingOperatorsStep',
  components: {
    BaseButton,
    BaseTextButton
  },
  emits: ['skip', 'submitStep'],
  props: {
    btnLoading: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      form: {
        operators: defaultOperatorsList,
      },
      emailRules: [
        {
          required: true,
          message: this.$t('adminTitles.FieldIsRequired'),
          trigger: 'blur',
        },
        {
          pattern: /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/,
          message: this.$t('telemed.invalidEmail'),
          trigger: 'blur',
        },
      ]
    };
  },
  computed: {
    ...mapGetters(['onboarding']),
  },
  mounted() {
    if (this.onboarding.operators.length) {
      this.form.operators = this.onboarding.operators.map((value, key) => ({key, value}));
    }
  },
  methods: {
    skipStep() {
      this.form.operators = defaultOperatorsList;
      this.updateOperators();
      this.$emit('skip');
    },
    updateOperators() {
      this.$store.dispatch(UPDATE_ONBOARDING, {
        operators: this.form.operators.map((val) => val.value),
      });
    },
    removeOperator(item) {
      const index = this.form.operators.indexOf(item);
      if (index !== -1) {
        this.form.operators.splice(index, 1);
      }
    },
    addOperator() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const key = this.form.operators.length + 1;
          this.form.operators.push({
            key,
            value: ''
          });
          setTimeout(() => {
            document.getElementById(`Operator_${key}`).querySelector('input').focus();
          }, 100);
        }
      });
    },
    submitStep() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.updateOperators();
          this.$emit('submitStep');
        }
      });
    }
  },
};
</script>

<style lang="scss">
.onboarding-operators {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 40px 70px 0px 70px;
  align-items: center;
}

.onboarding-operators__form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 190px;
  overflow: auto;

  .el-form-item__error {
    position: relative !important;
  }

  input {
    padding-right: 71px !important;
  }
}

.onboarding-operators__form-input {
  position: relative;
}

.onboarding-operators__form-input-btn {
  position: absolute;
  right: 16px;
  top: 12px;
}
.onboarding-operators__description {
  line-height: 20px !important;
  color: var(--solid-black-purple);
  text-align: center;
}
.onboarding-operators__caption{
  width: 100%;
  text-align: left;
}
</style>
