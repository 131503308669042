<template>
  <BaseSideModal
    @close="onClose"
  >
    <template #title>{{
        programEdit ? $t('adminTitles.editPlan') : $t('adminTitles.createNewPlan')
      }}
    </template>

    <el-form label-position="top">

      <el-form-item class="mb_16" :label="$t('adminTitles.planTitle')">
        <el-input
          :class="{'a-common__input-error': errors.name}"
          :placeholder="$t('adminTitles.createPlanTitle')"
          v-model="program.name"
        />
      </el-form-item>

      <el-form-item class="mb_16" :label="$t('adminTitles.insuranceCompany')">
        <el-select
          v-model="program.insuranceCompanyId"
          filterable
          clearable
          :placeholder="$t('placeholders.notSelected')"
          :class="{'a-common__input-error': errors.insuranceCompanyId}"
        >
          <el-option
            v-for="item in insuranceCompanies"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>

      <el-form-item class="mb_16" :label="$t('adminTitles.company')">
        <el-select
          v-model="program.clientCompanyId"
          filterable
          :clearable="true"
          :placeholder="$t('adminTitles.createPlanCompany')"
          :class="{'a-common__input-error': errors.clientCompanyId}"
        >
          <el-option
            v-for="item in clientCompaniesList.items"
            :key="item.id"
            :label="item.name"
            :value="item.clientCompanyId"
          />
        </el-select>
      </el-form-item>

      <el-form-item
        class="mb_16 program__input" :label="$t('adminTitles.mainProgramLanguage')">
        <el-select
          v-model="program.defaultLocale"
          filterable
          :placeholder="$t('adminTitles.createPlanLanguage')"
          :class="{'a-common__input-error': errors.defaultLocale}"
        >
          <el-option
            v-for="item in languages"
            :key="item.id"
            :label="item.title"
            :value="item.id">
          </el-option>
        </el-select>
        <BaseTooltip class="program__input-tooltip" :tooltip-text="$t('adminTitles.createPlanLanguageTooltip')"></BaseTooltip>
      </el-form-item>
    </el-form>
    <BaseDialog
      v-if="showConfirmationDialog"
      :title="$t('adminDialogs.confirmCancelPlanCreation')"
      :text="$t('adminDialogs.unsavedDataWillBeLost')"
      :confirm-button-text="$t('adminTitles.continue')"
      :cancel-button-text="$t('adminTitles.cancel')"
      @close="showConfirmationDialog = false"
      @confirm="$emit('close')"
    />

    <template #footer>
      <BaseButton
        type="outline"
        @click="onClose"
      >
        {{ $t('buttons.close') }}
      </BaseButton>
      <BaseButton
        :disabled="saveButtonDisabled"
        @click="saveProgram()"
      >
        {{ programEdit ? $t('buttons.save') : $t('buttons.create') }}
      </BaseButton>
    </template>
  </BaseSideModal>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  CHANGE_CLIENT_COMPANIES_FILTER_FOR_PROGRAM,
  CLIENT_COMPANIES_CLEAR,
} from '@/store/actions/client-companies';
import { PROGRAM_SAVE } from '@/store/actions/programs';
import BaseSideModal
  from '@/views/RenovaDashboard/components/ui-kit/BaseSideModal/BaseSideModal';
import BaseButton from '@/views/RenovaDashboard/components/ui-kit/Buttons/BaseButton';
import BaseDialog from '@/views/RenovaDashboard/components/ui-kit/BaseDialog';
import BaseTooltip from '@/views/RenovaDashboard/components/ui-kit/BaseTooltip';

function NewProgram() {
  this.defaultLocale = null;
  this.insuranceCompanyId = null;
  this.clientCompanyId = null;
  this.name = '';
  this.isAvailable = false;
}

export default {
  name: 'ModalCreateNewProgram',
  components: {
    BaseTooltip,
    BaseDialog,
    BaseButton,
    BaseSideModal,
  },
  emits: ['close', 'saved'],
  props: {
    /**
     * Program to edit, if present - modal will be in edit mode
     */
    programEdit: {
      type: Object,
      default: null,
    },
    insuranceCompanyId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      program: null,
      active: [
        {
          val: true,
          title: this.$t('adminTitles.active'),
        },
        {
          val: false,
          title: this.$t('adminTitles.inactive'),
        },
      ],
      errors: {
        defaultLocale: false,
        // insuranceCompanyId: false,
        // clientCompanyId: false,
        name: false,
      },
      /** @type {boolean} Flag to show confirmation dialog if have unsaved data */
      showConfirmationDialog: false,
    };
  },
  created() {
    if (this.programEdit) {
      this.program = JSON.parse(JSON.stringify(this.programEdit));
    } else {
      this.program = new NewProgram();
      this.$store.commit(CLIENT_COMPANIES_CLEAR);
      if (this.$route.query?.insuranceCompanyId) {
        this.program.insuranceCompanyId = this.$route.query?.insuranceCompanyId;
      } else if (this.insuranceCompanies.length) {
        this.program.insuranceCompanyId = this.insuranceCompanies[0].id;
      }
      if (this.insuranceCompanyId) {
        this.program.insuranceCompanyId = this.insuranceCompanyId;
      }
    }
  },
  watch: {
    'program.insuranceCompanyId': function () {
      this.$store.dispatch(CHANGE_CLIENT_COMPANIES_FILTER_FOR_PROGRAM, [this.program.insuranceCompanyId]);
    },
    insuranceCompanies(val) {
      if (val) {
        this.program.insuranceCompanyId = this.insuranceCompanies?.length === 1 ? this.insuranceCompanies[0]?.id : null;
        this.program.insuranceCompanyId = this.$route.query?.insuranceCompanyId;
      }
    },
    'clientCompaniesList.items': function (val) {
      if (this.programEdit) {
        return;
      }
      if (val) {
        this.program.clientCompanyId = this.clientCompaniesList.items.some((item) => item.clientCompanyId === this.$route.query?.clientCompanyId) ? this.$route.query?.clientCompanyId : null;
      }
    },
  },
  computed: {
    ...mapGetters(['programDetails', 'insuranceCompanies', 'clientCompaniesList', 'languages']),
    /**
     * Check if save button should be disabled (if not all fields are filled)
     * @returns {boolean}
     */
    saveButtonDisabled() {
      if (this.programEdit) {
        return false;
      }
      return Object.keys(this.program).filter((key) => key !== 'isAvailable' && key !== 'clientCompanyId' && key !== 'insuranceCompanyId').some((key) => !this.program[key]);
    },
    /**
     * Check if have unsaved data (if any field is filled)
     * @returns {boolean}
     */
    haveUnsavedData() {
      return Object.values(this.program).some((value) => value);
    },
  },
  methods: {
    onClose() {
      if (this.haveUnsavedData) {
        this.showConfirmationDialog = true;
      } else {
        this.$emit('close');
      }
    },
    saveProgram() {
      Object.keys(this.errors).forEach((key) => {
        this.errors[key] = !this.program[key] || !this.program[key].length;
      });

      const allValid = Object.values(this.errors).every((value) => !value);

      if (allValid) {
        this.program.clientCompanyId = this.program.clientCompanyId && this.program.clientCompanyId.length ? this.program.clientCompanyId : null;
        this.$store.dispatch(PROGRAM_SAVE, {
          ...this.program,
          insuranceCompanyId: this.program.insuranceCompanyId || null,
          insuranceCompanyIdSave: this.program.insuranceCompanyId || null,
          insuranceCompanyIdDelete: !this.program.insuranceCompanyId,
        }).then((resp) => {
          this.$notify.success({
            message: `${this.$t('adminTitles.success')}`,
          });

          if (this.programEdit) {
            this.$emit('saved');
          } else {
            this.$router.push(`/admin/programs/${resp.packageId}`);
          }
        })
          .catch(() => {
            this.$notify.error({
              message: `${this.$t('adminTitles.someError')}`,
            });
          });
      }
    },
  },
};
</script>

<style scoped>
::v-deep .el-select {
  display: inline;
}
.program__input {
  flex-basis: calc(50% - 8px);
  position: relative;
}
.program__input-tooltip {
  position: absolute;
  right: 0;
  top: -22px;
}

</style>
