/* eslint-disable no-param-reassign */

import axios from 'axios';
import i18n from '@/i18n';
import {
  CLINICS_REQUEST, CLINICS_SUCCESS, CLINICS_DELETE_SUCCESS, CLINICS_DELETE_REQUEST, CLINICS_FILTERS_CHANGE,
} from '../actions/clinics';

function FiltersDefault(offerIds, insuranceCompanyIds, cityIds) {
  this.page = 1;
  this.limit = 10000;
  this.offerIds = offerIds || [];
  this.insuranceCompanyIds = insuranceCompanyIds || [];
  this.cityIds = [];
}

const state = {
  clinics: {
    items: '',
    cities: [],
    totalCount: 0,
    loading: false,
    deleteLoading: false,
  },
  filters: new FiltersDefault(),
};

const getters = {
  clinics: () => state.clinics,
  clinicsFilters: () => state.filters,
};

const actions = {
  [CLINICS_REQUEST]({ commit, state }) {
    commit(CLINICS_REQUEST);

    const filters = JSON.parse(JSON.stringify(state.filters));
    filters.page -= 1;

    return axios.post(
      '/insurance/clinic/list',
      filters,
      { headers: { 'X-Locale': i18n.locale } },
    )
      .then((resp) => {
        commit(CLINICS_SUCCESS, resp.data);
        return Promise.resolve();
      })
      .catch((err) => {
        commit(CLINICS_REQUEST);
        return Promise.reject(err);
      });
  },

  [CLINICS_FILTERS_CHANGE]({ commit, dispatch }, filters) {
    commit(CLINICS_FILTERS_CHANGE, filters);
    dispatch(CLINICS_REQUEST);
  },

  [CLINICS_DELETE_REQUEST]({ commit }, params) {
    commit(CLINICS_DELETE_REQUEST);

    return axios.post(
      'insurance/offer/save',
      params,
      { headers: { 'X-Locale': i18n.locale } },
    )
      .then((resp) => {
        commit(CLINICS_DELETE_SUCCESS, resp.data);
        return Promise.resolve();
      })
      .catch((err) => {
        commit(CLINICS_DELETE_REQUEST);
        return Promise.reject(err.response.data);
      });
  },
};

const mutations = {
  [CLINICS_REQUEST]: (state) => {
    state.clinics.items = [];
    state.clinics.loading = !state.clinics.loading;
  },
  [CLINICS_SUCCESS]: (state, resp) => {
    state.clinics.items = resp.items;
    state.clinics.totalCount = resp.totalCount;

    state.clinics.cities = resp.items.reduce((acc, obj) => {
      if (obj.city && acc.every((item) => item.id !== obj?.city?.id)) {
        acc.push(obj.city);
      }
      return acc;
    }, []);

    state.clinics.loading = false;
  },
  [CLINICS_FILTERS_CHANGE]: (state, filters) => {
    state.filters = filters;
  },
  [CLINICS_DELETE_REQUEST]: (state) => {
    state.clinics.deleteLoading = !state.clinics.deleteLoading;
  },
  [CLINICS_DELETE_SUCCESS]: (state) => {
    state.clinics.deleteLoading = false;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
