import axios from 'axios';

import { USER_DETAILS_REQUEST } from '@/store/actions/users';
import i18n from '@/i18n';
import {
  RECEPTION_GET_OFFERS,
  RECEPTION_GET_CLINICS,
  RECEPTION_GET_CITIES,
  RECEPTION_SAVE,
  RECEPTION_DETAILS_REQUEST,
  RECEPTION_DOCTOR_CALL_DETAILS_REQUEST,
  RECEPTION_DETAILS_COMMON_SUCCESS,
  RECEPTION_LOADING,
  RECEPTION_REQUEST_ERROR,
  RECEPTION_DETAILS_CLEAR,
  RECEPTION_CANCEL,
  RECEPTION_OFFERS_LOADING,
  RECEPTION_CITIES_LOADING,
  RECEPTION_CLINICS_LOADING, RECEPTION_TELEMED_DETAILS_REQUEST,
} from '../actions/reception';

import Users from './users';

const state = {
  reception: {
    offers: [],
    clinics: [],
    cities: [],
    saveLoading: false,
  },
  receptionDetails: {
    reception: null,
    receptionError: false,
    loading: false,
  },
  receptionLoadings: {
    clinicsLoading: false,
    citiesLoading: false,
    offersLoading: false,
  },
};

const getters = {
  receptionData: () => state.reception,
  receptionDetails: () => state.receptionDetails,
  receptionLoadings: () => state.receptionLoadings,
};

const actions = {
  [RECEPTION_DETAILS_REQUEST]({ commit }, reception) {
    commit(RECEPTION_LOADING);
    return axios.post(
      '/insurance/reception/detail',
      reception,
      { headers: { 'X-Locale': i18n.locale } },
    )
      .then((resp) => {
        commit(RECEPTION_DETAILS_COMMON_SUCCESS, resp.data);
        return Promise.resolve();
      })
      .catch((err) => {
        commit(RECEPTION_REQUEST_ERROR);
        console.log(err.response.data);
        return Promise.reject(err.response.data);
      });
  },

  [RECEPTION_DOCTOR_CALL_DETAILS_REQUEST]({ commit }, reception) {
    commit(RECEPTION_LOADING);
    return axios.post(
      '/insurance/doctor-call/detail',
      reception,
      { headers: { 'X-Locale': i18n.locale } },
    )
      .then((resp) => {
        commit(RECEPTION_DETAILS_COMMON_SUCCESS, resp.data);
        return Promise.resolve();
      })
      .catch((err) => {
        commit(RECEPTION_REQUEST_ERROR);
        console.log(err.response.data);
        return Promise.reject(err.response.data);
      });
  },
  [RECEPTION_TELEMED_DETAILS_REQUEST]({ commit }, telemedicineReceptionId) {
    commit(RECEPTION_LOADING);
    return axios.post(
      '/insurance/reception/telemedicine/detail',
      telemedicineReceptionId,
      { headers: { 'X-Locale': i18n.locale } },
    )
      .then((resp) => {
        commit(RECEPTION_DETAILS_COMMON_SUCCESS, {
          ...resp.data,
          type: 'TELEMEDICINE_RECEPTION',
        });
        return Promise.resolve();
      })
      .catch((err) => {
        commit(RECEPTION_REQUEST_ERROR);
        console.log(err.response.data);
        return Promise.reject(err.response.data);
      });
  },

  [RECEPTION_GET_OFFERS]({ commit }, programId) {
    commit(RECEPTION_OFFERS_LOADING);
    return axios.post(
      '/insurance/reception/offer/list',
      programId,
      { headers: { 'X-Locale': i18n.locale } },
    )
      .then((resp) => {
        commit(RECEPTION_GET_OFFERS, resp.data);
        return Promise.resolve();
      })
      .catch((err) => {
        commit(RECEPTION_OFFERS_LOADING);
        return Promise.reject(err.response.data);
      });
  },

  [RECEPTION_GET_CITIES]({ commit }, offerId) {
    commit(RECEPTION_CITIES_LOADING);
    return axios.post(
      '/insurance/reception/city/list',
      offerId,
      { headers: { 'X-Locale': i18n.locale } },
    )
      .then((resp) => {
        commit(RECEPTION_GET_CITIES, resp.data);
        return Promise.resolve();
      })
      .catch((err) => {
        commit(RECEPTION_CITIES_LOADING);
        return Promise.reject(err.response.data);
      });
  },

  [RECEPTION_GET_CLINICS]({ commit }, offerId) {
    commit(RECEPTION_CLINICS_LOADING);
    return axios.post(
      '/insurance/reception/clinic/list',
      offerId,
      { headers: { 'X-Locale': i18n.locale } },
    )
      .then((resp) => {
        commit(RECEPTION_GET_CLINICS, resp.data);
        return Promise.resolve();
      })
      .catch((err) => {
        commit(RECEPTION_CLINICS_LOADING);
        return Promise.reject(err.response.data);
      });
  },

  [RECEPTION_SAVE]({ commit, dispatch }, reception) {
    commit(RECEPTION_SAVE);
    return axios.post(
      '/insurance/reception/save',
      reception,
      { headers: { 'X-Locale': i18n.locale } },
    )
      .then((resp) => {
        commit(RECEPTION_SAVE);
        commit(RECEPTION_DETAILS_COMMON_SUCCESS, resp.data);
        setTimeout(() => {
          dispatch(USER_DETAILS_REQUEST, { personProfileId: reception.personProfileId });
        }, 100);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        commit(RECEPTION_SAVE);
        return Promise.reject(err.response.data);
      });
  },
  [RECEPTION_CANCEL]({ commit, dispatch }, reception) {
    commit(RECEPTION_SAVE);
    return axios.post('/insurance/reception/cancel', reception)
      .then((resp) => {
        commit(RECEPTION_DETAILS_COMMON_SUCCESS, resp.data);
        dispatch(USER_DETAILS_REQUEST, { personProfileId: reception.personProfileId });
        return Promise.resolve(resp);
      })
      .catch((err) => Promise.reject(err.response.data));
  },
};

const mutations = {
  [RECEPTION_LOADING]: (state) => {
    state.receptionDetails.loading = true;
    state.receptionDetails.receptionError = false;
    state.receptionDetails.reception = null;
  },
  [RECEPTION_DETAILS_COMMON_SUCCESS]: (state, resp) => {
    state.receptionDetails.reception = resp;
    state.receptionDetails.reception.offerId = resp?.offer?.id;
    state.receptionDetails.reception.cityId = resp?.city?.id;
    state.receptionDetails.reception.clinicId = resp?.clinic?.id;
    state.receptionDetails.reception.receptionId = resp.id;
    state.receptionDetails.reception.dateTimeNotFormated = state.receptionDetails.reception.dateTime;
    state.receptionDetails.loading = false;
  },
  [RECEPTION_REQUEST_ERROR]: (state) => {
    state.receptionDetails.reception = true;
  },

  [RECEPTION_GET_OFFERS]: (state, resp) => {
    state.reception.offers = resp.items;
    state.receptionLoadings.offersLoading = false;
  },
  [RECEPTION_GET_CITIES]: (state, resp) => {
    state.reception.cities = resp.items;
    state.receptionLoadings.citiesLoading = false;
  },
  [RECEPTION_GET_CLINICS]: (state, resp) => {
    state.reception.clinics = resp.items;
    state.receptionLoadings.clinicsLoading = false;
  },
  [RECEPTION_SAVE]: (state) => {
    state.reception.saveLoading = !state.reception.saveLoading;
  },
  [RECEPTION_DETAILS_CLEAR]: (state) => {
    state.receptionDetails.reception = null;
  },

  [RECEPTION_CITIES_LOADING]: (state) => {
    state.receptionLoadings.citiesLoading = !state.receptionLoadings.citiesLoading;
  },
  [RECEPTION_OFFERS_LOADING]: (state) => {
    state.receptionLoadings.offersLoading = !state.receptionLoadings.offersLoading;
  },
  [RECEPTION_CLINICS_LOADING]: (state) => {
    state.receptionLoadings.clinicsLoading = !state.receptionLoadings.clinicsLoading;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
