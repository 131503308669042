<template>
  <div
    class="hr-header"
    :class="{'hr-header--dark' : isDark}"
  >
    <div
      class="hr-header__logo"
      :style="{'background-image': 'url(' + companyLogo + ')'}"
      @click="openLanding"
    ></div>
    <div class="hr-header__btns-container">
      <BaseSimpleDropdown
        v-if="user.id && corporateData.selectedCompany"
        id="dropdownDomains"
        :selected-option="corporateData.selectedCompany ? corporateData.selectedCompany.domainId : null"
        :options="domainsFilterOptions"
        :button-text="'Company'"
        @selectOption="selectCompany"
      />
      <BaseSimpleDropdown
        id="dropdownLangs"
        :options="langOptions"
        :selected-option="currentLang"
        @selectOption="changeLanguage"
      />
      <div
        v-if="user.id"
        @click="showLogoutConfirm = true"
        class="hr-header__btn-logout body-text">
        {{ $t('adminTitles.logout') }}
      </div>
    </div>
    <BaseDialog
      v-if="showLogoutConfirm"
      :text="$t('adminTitles.logoutConfirm')"
      :confirm-button-text="$t('adminTitles.logout')"
      :cancel-button-text="$t('buttons.cancel')"
      @close="showLogoutConfirm = false"
      @confirm="logout"
    />
  </div>
</template>
<script>

import BaseSimpleDropdown
  from '@/views/RenovaDashboard/components/ui-kit/Dropdowns/BaseSimpleDropdown';
import { mapGetters } from 'vuex';
import locale from 'element-ui/lib/locale';
import i18n from '@/i18n';
import BaseDialog from '@/views/RenovaDashboard/components/ui-kit/BaseDialog';
import { AUTH_LOGOUT } from '@/store/actions/auth';
import {
  CUP_AVAILABLE_DATES_REQUEST,
  CUP_CLIENT_LIST_REQUEST,
  CUP_FILES_LIST_GET,
  CUP_SET_COMPANY,
  CUP_TICKETS_LIST_GET,
} from '@/store/actions/hr-user-page';
import { GET_HR_DICTIONARY } from '@/store/actions/dictionary';
import { getCompanyLogo } from '@/whitelabel-utils';

export default {
  name: 'HrHeader',
  components: {
    BaseDialog,
    BaseSimpleDropdown,
  },
  props: {
    isDark: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentLang: null,
      langOptions: [
        {id: 'en', text: 'English'},
        {id: 'es', text: 'Español'},
        {id: 'ru', text: 'Русский'},
        {id: 'pt', text: 'Português'},
      ],
      showLogoutConfirm: false,
      companyLogo: getCompanyLogo(),
    };
  },
  computed: {
    ...mapGetters(['user', 'languages', 'corporateData']),
    selectedLanguage() {
      return this.languages.find((lang) => lang.id === this.currentLang);
    },
    domainsFilter() {
      return this.user?.domains?.filter((item) => item.type === 'CLIENT_COMPANY_ADMIN_WORKSPACE');
    },
    domainsFilterOptions() {
      return this.domainsFilter.map((val) => ({id: val.domainId, text: val.name}));
    },
  },
  created() {
    this.currentLang = localStorage.getItem('lang');
  },
  methods: {
    changeLanguage(lang) {
      this.currentLang = lang;
      i18n.locale = this.currentLang;
      localStorage.setItem('lang', this.currentLang);
      locale.use(this.$elementLangs[i18n.locale]);
      this.$store.dispatch(CUP_CLIENT_LIST_REQUEST);
      this.$store.dispatch(CUP_TICKETS_LIST_GET);
      this.$store.dispatch(GET_HR_DICTIONARY);
    },
    logout() {
      this.$store.dispatch(AUTH_LOGOUT)
        .then(() => {
          this.showLogoutConfirm = false;
          this.$router.push('/hr/login');
        });
    },
    selectCompany(id) {
      const company = this.domainsFilter.find((val) => val.domainId === id);
      this.$store.commit(CUP_SET_COMPANY, company);
      this.$store.dispatch(CUP_CLIENT_LIST_REQUEST);
      this.$store.dispatch(CUP_FILES_LIST_GET);
      this.$store.dispatch(CUP_TICKETS_LIST_GET);
      this.activeTab = 'cup-client-list';
      this.loadAvailableDates();
      // this.loadUsedesk();
    },
    loadAvailableDates() {
      this.$store.dispatch(CUP_AVAILABLE_DATES_REQUEST, 'COMPANY_EMPLOYEE_ADD');
      this.$store.dispatch(CUP_AVAILABLE_DATES_REQUEST, 'COMPANY_EMPLOYEE_REMOVE');
    },
    openLanding() {
      if (window.whiteLabelCompany === 'renova') {
        if (window.isDev) {
          window.open(`https://exp.renova.world/${i18n.locale}`, '_current');
        } else {
          window.open(`https://renova.world/${i18n.locale}`, '_current');
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.hr-header {
  height: 56px;
  width: 100%;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--solid-lavender-grey);

  &--dark {
    border-bottom: none;
    background-color: var(--solid-light-purple);
  }
}

.hr-header__logo {
  width: 28px;
  height: 28px;
  background-size: contain;
  cursor: pointer;
}

.hr-header__btns-container {
  display: flex;
  align-items: center;
  gap: 24px;
}

.hr-header__btn-logout {
  cursor: pointer;
  color: var(--solid-dark-purple);
}
</style>
