<template>
  <div>
    <AdminPageTitle>
      {{ $t('adminTitles.programs') }}
    </AdminPageTitle>
    <div class="programs-toolbar">
      <div class="programs-filters">
        <InputSearch
          :value="searchKey"
          @searchUpdate="searchKey =  $event"
        ></InputSearch>
        <BaseDropdown
          :selectedOptions="filters.clientCompanyIds ? filters.clientCompanyIds : []"
          :options="clientCompanies"
          :multiple="true"
          :width="'200px'"
          :placeholder="$t('adminTitles.company')"
          @changeOptions="updateFilterValue('clientCompanyIds', $event)"
        />
        <BaseDropdown
          :selectedOptions="filters.insuranceCompanyIds ? filters.insuranceCompanyIds : []"
          :options="filteredInsuranceCompanies"
          :multiple="true"
          :width="'200px'"
          :placeholder="$t('adminTitles.insuranceCompanyShort')"
          @changeOptions="updateFilterValue('insuranceCompanyIds', $event)"
        />
        <BaseDropdown
          :selectedOptions="statusFilterValue"
          :options="activeList"
          :multiple="false"
          :width="'200px'"
          :placeholder="$t('adminTitles.status')"
          @changeOptions="updateActiveFilter"
        />
      </div>
      <BaseButton @click="showCreateNewProgram = true">
        {{ $t('buttons.newProgram') }}
      </BaseButton>
    </div>

    <div class="programs-table">
      <el-skeleton
        v-if="isDataLoading"
        :rows="20"
      />

      <BaseTable
        v-else-if="programsTableData"
        :headers="programsTableHeaders"
        :data="programsTableData"
        :row-clickable="true"
        @rowClick="openProgram"
      >
        <BaseTableColumn name="Name">{{ $t('adminTitles.name') }}</BaseTableColumn>
        <BaseTableColumn name="Company">{{ $t('adminTitles.company') }}</BaseTableColumn>
        <BaseTableColumn name="Provider">{{
            $t('adminTitles.insuranceCompanyShort')
          }}
        </BaseTableColumn>
        <BaseTableColumn name="Services">{{ $t('adminTitles.services') }}</BaseTableColumn>
        <BaseTableColumn name="People">{{ $t('adminTitles.people') }}</BaseTableColumn>
        <BaseTableColumn name="Status">{{ $t('adminTitles.status') }}</BaseTableColumn>
        <template #Name="{value}">
         <p  class="body-text color--purple">{{ value }}</p>
        </template>
        <template #Company="cell"><span v-html="cell.value"/></template>
        <template #Status="cell"><span v-html="cell.value"/></template>
        <template #Services="{row}">
          <span v-if="row.offerGenerationStatus !== 'IN_PROCESS'">{{ row.Services }}</span>
          <BasePreloader v-if="row.offerGenerationStatus === 'IN_PROCESS'" :size="'m'" :color="'purple'"></BasePreloader>
        </template>
      </BaseTable>
      <NoResultsPlaceholder v-else/>
    </div>
    <ModalCreateNewProgram
      v-if="showCreateNewProgram"
      @close="showCreateNewProgram = false"
    />
  </div>
</template>

<script>

import AdminPageTitle from '@/views/RenovaDashboard/CommonParts/AdminPageTitle';
import { mapGetters } from 'vuex';
import {
  PROGRAMS_LIST_FILTERS_CHANGE,
  PROGRAMS_LIST_FILTERS_CLEAR,
  PROGRAMS_LIST_REQUEST
} from '@/store/actions/programs';
import { CLIENT_COMPANIES_REQUEST } from '@/store/actions/client-companies';
import BaseButton from '@/views/RenovaDashboard/components/ui-kit/Buttons/BaseButton';
import ModalCreateNewProgram from '@/views/RenovaDashboard/ModalPages/ModalCreateNewProgram';
import BaseTable from '@/views/RenovaDashboard/components/ui-kit/BaseTable/BaseTable';
import NoResultsPlaceholder
  from '@/views/RenovaDashboard/components/ui-kit/NoResultsPlaceholder';
import { adminEventBus } from '@/layouts/RenovaDashboardLayout';
import BaseDropdown from '@/views/RenovaDashboard/components/ui-kit/Dropdowns/BaseDropdown';
import InputSearch from '@/views/RenovaDashboard/components/ui-kit/Inputs/InputSearch';
import BasePreloader from '@/views/RenovaDashboard/components/ui-kit/Buttons/BasePreloader';
import BaseTableColumn from '../components/ui-kit/BaseTable/BaseTableColumn';

export default {
  name: 'TheProgramsView',
  components: {
    BasePreloader,
    InputSearch,
    BaseDropdown,
    NoResultsPlaceholder,
    ModalCreateNewProgram,
    BaseButton,
    BaseTable,
    AdminPageTitle,
    BaseTableColumn,
  },
  data() {
    return {
      filters: {},
      searchKey: '',
      activeFilter: null,
      activeList: [
        {
          id: 'active',
          name: this.$t('adminTitles.activeProgram'),
        },
        {
          id: 'inactive',
          name: this.$t('adminTitles.inactiveProgram'),
        },
      ],
      showCreateNewProgram: false,
      showAddServiceModal: true,
    };
  },
  beforeDestroy() {
    this.$store.dispatch(PROGRAMS_LIST_FILTERS_CLEAR);
  },
  watch: {
    programsListFilters: {
      handler() {
        this.filters = JSON.parse(JSON.stringify(this.programsListFilters));
      },
      deep: true,
    },
    searchKey() {
      this.$store.commit('PROGRAMS_LIST_SEARCH_QUERY_CHANGE', this.searchKey);
    },
    activeFilter() {
      this.$store.commit('PROGRAMS_LIST_STATUS_FILTER_CHANGE', this.activeFilter);
    },
  },
  computed: {
    ...mapGetters([
      'programsList',
      'programsListFilters',
      'programsStatusFilter',
      'programsSearchQuery',
      'ticketsFiltersData',
      'insuranceCompanies',
      'dictionaryLoading',
      'clientCompanies',
      'clientCompaniesList',
      'filteredInsuranceCompaniesByCompanyIds',
    ]),
    /**
     * Check if data necessary for table is loading
     * @returns {boolean}
     */
    isDataLoading() {
      return this.programsList.loading || this.dictionaryLoading || this.clientCompaniesList.loading;
    },
    /**
     * Filtered insurance companies by selected client company ids
     * @returns {*}
     */
    filteredInsuranceCompanies() {
      // If no client company selected, return all insurance companies
      if (!this.filters.clientCompanyIds.length) {
        return this.insuranceCompanies;
      }
      return this.filteredInsuranceCompaniesByCompanyIds(this.filters.clientCompanyIds);
    },
    statusFilter() {
      return this.activeFilter !== ''
        ? this.programsList.items.filter((item) => item.isAvailable === this.activeFilter)
        : this.programsList.items;
    },
    statusFilterValue() {
      if (this.activeFilter === '') {
        return [];
      }
      if (!this.activeFilter) {
        return ['inactive'];
      }
      return ['active'];
    },
    filterPrograms() {
      return this.statusFilter.filter((item) => item.name.toLowerCase()
        .includes(this.searchKey.toLowerCase()));
    },
    /**
     * Prepare Headers for table columns
     * @returns {{Status: VueI18n.TranslateResult, Services: VueI18n.TranslateResult, Company: VueI18n.TranslateResult, People: VueI18n.TranslateResult, Name: VueI18n.TranslateResult, Provider: VueI18n.TranslateResult}}
     */
    programsTableHeaders() {
      return {
        Name: this.$t('adminTitles.name'),
        Company: this.$t('adminTitles.company'),
        Provider: this.$t('adminTitles.insuranceCompanyShort'),
        Services: this.$t('adminTitles.services'),
        People: this.$t('adminTitles.people'),
        Status: this.$t('adminTitles.status'),
      };
    },
    /**
     * Prepare data for programs table
     * @returns {{Status: string, Services: *, Company: string|*|string, People: *, Name: *, Provider: *}[]}
     */
    programsTableData() {
      const companyLabel = (item) => {
        if (item.clientCompany) {
          const company = this.clientCompaniesList.items.find((company) => company.clientCompanyId === item.clientCompany.id);
          if (company && company.logo) {
            const companyLogo = `<div class="company-logo"><img src="${company?.logo.link}" alt="company-logo"/></div>`;
            return `<div class="company-with-logo">${companyLogo} ${item.clientCompany.name}</div>`;
          }
          return item.clientCompany.name;
        }

        return '';
      };

      return this.filterPrograms.map((item) => ({
        Id: item.packageId,
        Name: item.name,
        Company: companyLabel(item),
        Provider: item.insuranceCompany?.name,
        Services: item.offerCount,
        offerGenerationStatus: item.offerGenerationStatus,
        People: item.userCount,
        Status: `<span class="row-status status-${item.isAvailable ? 'green' : 'orange'}">${item.isAvailable ? this.$t('adminTitles.activeProgram') : this.$t('adminTitles.inactiveProgram')}</span>`,
      }));
    },
  },
  created() {
    this.$store.dispatch(PROGRAMS_LIST_REQUEST);
    this.$store.dispatch(CLIENT_COMPANIES_REQUEST);
    this.filters = JSON.parse(JSON.stringify(this.programsListFilters));
    this.searchKey = this.programsSearchQuery;
    this.activeFilter = this.programsStatusFilter;
    this.setDefaultFilters();
  },
  mounted() {
    adminEventBus.$emit('breadcrumbs', [
      { title: this.$t('adminTitles.programs'), link: '/admin/programs' },
    ]);
  },
  methods: {
    setDefaultFilters() {
      if (this.$route.query.clientCompany) {
        this.updateFilterValue('clientCompanyIds', [this.$route.query.clientCompany]);
        this.activeFilter = true;
      }
    },
    updateFilterValue(field, value) {
      this.filters[field] = value;
      this.changeFilter();
    },
    changeFilter() {
      this.filters.page = 1;
      this.$store.dispatch(PROGRAMS_LIST_FILTERS_CHANGE, this.filters);
    },
    updateActiveFilter(value) {
      this.activeFilter = !value.length ? '' : (value[0] === 'active');
    },
    /**
     * Handle programs table row click
     * @param row - item of programsList.data
     */
    openProgram(row) {
      this.$router.push({
        path: `/admin/programs/${row.Id}`,
        query: {
          deactivationRedirect: this.$route.query.redirectTo,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.programs-toolbar {
  padding: 24px 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;
  position: sticky;
  top: -32px;
  background-color: white;
}

.programs-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

::v-deep .company-with-logo {
  display: flex;
  align-items: center;

  .company-logo {
    width: 24px;
    height: 24px;
    border-radius: 190px;
    margin-right: 8px;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    img {
      height: 100%;
      object-fit: contain;
      width: 24px;
    }
  }
}

</style>
