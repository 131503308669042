<template>
  <BaseOverlay @click.native.self.exact="$emit('close')">
    <div
      class="side-modal"
      :style="style"
    >
      <div class="side-modal__header modal-title">
        <slot name="title"/>
        <div
          v-if="showCloseButton"
          class="side-modal__close"
          @click="$emit('close')"
        >
          <img
            class="side-modal__icon-cross"
            src="/img/renova-dashboard/icons/x.svg"
            alt="x"
          >
        </div>
      </div>
      <div
        class="side-modal__content"
        :class="{'side-modal__content--fullHeight': !showFooter}"
      >
        <slot/>
      </div>
      <div v-if="showFooter" class="side-modal__footer">
        <slot name="footer"/>
      </div>
    </div>
  </BaseOverlay>
</template>

<script>
import BaseOverlay
  from '@/views/RenovaDashboard/components/ui-kit/BaseOverlay';

export default {
  name: 'BaseSideModal',
  components: {
    BaseOverlay,
  },
  emits: ['close'],
  props: {
    width: {
      type: String,
      default: '500px',
      required: false,
    },
    showCloseButton: {
      type: Boolean,
      default: true,
      required: false,
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    style() {
      return {
        'max-width': this.width,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.side-modal {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  min-height: 100vh;
  background: var(--white);
  z-index: 1001;
  overflow-y: auto;
  background-color: #FFFFFF;

  &__header {
    border-bottom: 1px solid var(--solid-lavender-grey);
    color: var(--solid-black-purple);
    margin: 16px 24px 24px;
    padding-bottom: 16px;
  }

  &__close {
    cursor: pointer;
    position: absolute;
    top: 16px;
    right: 22px;

    &-icon-cross {
      width: 20px;
      height: 20px;
    }
  }

  &__content {
    padding: 0 24px;
    height: calc(100vh - 171px);
    overflow: hidden;
    overflow-y: scroll;
    &--fullHeight{
      height: calc(100vh - 80px);
    }
  }

  &__footer {
    display: flex;
    gap: 16px;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    box-shadow: 0px -4px 4px 0px #00000005;
  }
}
</style>
