export const stepTypes = {
  COMPANY_DETAILS: 'COMPANY_DETAILS',
  OPERATORS: 'OPERATORS',
  INTEGRATIONS: 'INTEGRATIONS',
  ONBOARDING_SUBMIT: 'ONBOARDING_SUBMIT',
};
export const stepsInfo = {
  [stepTypes.COMPANY_DETAILS]: {
    stepTitle: 'onboarding.AddCompanyDetails',
    component: 'OnboardingCompanyStep',
    img: {
      src: '/img/renova-dashboard/onboarding/company-preview.png',
      styles: {
        width: '240px',
        height: '370px',
        right: '-36px',
        bottom: '-7px'
      }
    },
  },
  [stepTypes.OPERATORS]: {
    stepTitle: 'onboarding.AddOperatorsEmails',
    component: 'OnboardingOperatorsStep',
    img: {
      src: '/img/renova-dashboard/onboarding/operators-preview.png',
      styles: {
        width: '400px',
        height: '252px',
        right: '50px',
        bottom: '0px'
      }
    },
  },
  [stepTypes.INTEGRATIONS]: {
    stepTitle: 'onboarding.IntegrateWhatsApp',
    component: 'OnboardingIntegrationStep',
    img: {
      src: '/img/renova-dashboard/onboarding/integrations-preview.png',
      styles: {
        width: '400px',
        height: '252px',
        right: '50px',
        bottom: '0px'
      }
    },
  },
  [stepTypes.ONBOARDING_SUBMIT]: {
    component: 'OnboardingSubmitStep',
  },
};
export const stepsList = [stepTypes.COMPANY_DETAILS, stepTypes.OPERATORS, stepTypes.INTEGRATIONS, stepTypes.ONBOARDING_SUBMIT];
