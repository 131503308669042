import axios from 'axios';
import i18n from '@/i18n';

import {
  COMPANY_GRANTED_LIST_REQUEST,
  COMPANY_GRANTED_LIST_SUCCESS,
  COMPANY_USER_SEARCH_REQUEST,
  COMPANY_USER_SEARCH_SUCCESS,
  COMPANY_USER_CREATE,
  COMPANY_GRANT_ADD,
  COMPANY_GRANT_DELETE,
} from '../actions/client-companies-grants';

const state = {
  companyGrants: {
    users: {
      items: [],
      loading: false,
    },
    userSearch: {
      user: null,
      loading: false,
    },
    saveLoading: false,
  },
};

const getters = {
  companyGrants: () => state.companyGrants,
};

const actions = {
  [COMPANY_GRANTED_LIST_REQUEST]({ commit, state }, companyId) {
    commit(COMPANY_GRANTED_LIST_REQUEST);

    const params = {
      clientCompanyIds: [companyId],
      limit: 1000,
      page: 0,
    };

    return axios.post(
      '/insurance/authorization/grant/list',
      {
        ...params,
        roles: ['HR'],
        // domainTypes: ['CLIENT_COMPANY_ADMIN_WORKSPACE'],
      },
      { headers: { 'X-Locale': i18n.locale } },
    )
      .then((resp) => {
        commit(COMPANY_GRANTED_LIST_SUCCESS, resp.data);
        return Promise.resolve();
      })
      .catch((err) => {
        commit(COMPANY_GRANTED_LIST_REQUEST);
        return Promise.reject(err.response.data);
      });
  },

  [COMPANY_USER_SEARCH_REQUEST]({ commit, state }, params) {
    commit(COMPANY_USER_SEARCH_REQUEST);

    return axios.post(
      '/insurance/authorization/user/detail',
      params,
      { headers: { 'X-Locale': i18n.locale } },
    )
      .then((resp) => {
        commit(COMPANY_USER_SEARCH_SUCCESS, resp.data);
        return Promise.resolve();
      })
      .catch((err) => {
        commit(COMPANY_USER_SEARCH_REQUEST);
        return Promise.reject(err.response);
      });
  },

  [COMPANY_USER_CREATE]({ commit, state }, user) {
    return axios.post(
      '/insurance/authorization/user/save',
      user,
      { headers: { 'X-Locale': i18n.locale } },
    )
      .then((resp) => Promise.resolve(resp.data))
      .catch((err) => Promise.reject(err.response.data));
  },

  [COMPANY_GRANT_ADD]({ commit, state }, params) {
    commit(COMPANY_GRANT_ADD);

    return axios.post(
      '/insurance/authorization/grant/save',
      params,
      { headers: { 'X-Locale': i18n.locale } },
    )
      .then((resp) => {
        commit(COMPANY_GRANT_ADD, resp.data);
        return Promise.resolve();
      })
      .catch((err) => {
        commit(COMPANY_GRANT_ADD);
        return Promise.reject(err.response.data);
      });
  },
  [COMPANY_GRANT_DELETE]({ commit, state }, params) {
    commit(COMPANY_GRANT_DELETE);

    return axios.post(
      '/insurance/authorization/grant/delete',
      params,
      { headers: { 'X-Locale': i18n.locale } },
    )
      .then((resp) => {
        commit(COMPANY_GRANT_ADD, resp.data);
        return Promise.resolve();
      })
      .catch((err) => {
        commit(COMPANY_GRANT_ADD);
        return Promise.reject(err.response.data);
      });
  },
};

const mutations = {
  [COMPANY_GRANTED_LIST_REQUEST]: (state) => {
    state.companyGrants.users.items = [];
    state.companyGrants.users.loading = true;
  },

  [COMPANY_GRANTED_LIST_SUCCESS]: (state, resp) => {
    state.companyGrants.users.items = resp.items;
    state.companyGrants.users.loading = false;
  },

  [COMPANY_USER_SEARCH_REQUEST]: (state) => {
    state.companyGrants.userSearch.user = null;
    state.companyGrants.userSearch.loading = !state.companyGrants.userSearch.loading;
  },
  [COMPANY_USER_SEARCH_SUCCESS]: (state, resp) => {
    state.companyGrants.userSearch.user = resp;
    state.companyGrants.userSearch.loading = false;
  },

  [COMPANY_GRANT_ADD]: (state) => {
    state.companyGrants.saveLoading = !state.companyGrants.saveLoading;
  },
  [COMPANY_GRANT_DELETE]: (state) => {
    state.companyGrants.saveLoading = !state.companyGrants.saveLoading;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
