<template>
  <div class="onboarding-integration">
    <img width="56px" height="56px" class="mb_40"
         src="/img/renova-dashboard/icons/notification--success.svg" alt="done">
    <p class="h2 mb_12 color--black-purple text_ac">
      {{ $t('onboarding.CongratulationsYouSettingUpAccount') }}
    </p>
    <p class="onboarding-integration__description body-text">
      {{ $t('onboarding.YouAreJustOneClickAway') }}
    </p>
    <BaseButton
      :loading="btnLoading"
      class="mt_40 mb_16"
      @click="submitOnboarding"
    >
      {{ $t('onboarding.StartUsingRenova') }}
    </BaseButton>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BaseButton from '@/views/RenovaDashboard/components/ui-kit/Buttons/BaseButton';
import {
  SAVE_INSURANCE_CONFIG,
  SAVE_ONBOARDING,
  UPDATE_ONBOARDING
} from '@/store/actions/onboarding';
import { USER_REQUEST } from '@/store/actions/user';
import {
  EMPLOYEE_SAVE_REQUEST,
  EMPLOYEE_SEND_WELCOME_EMAIL_REQUEST
} from '@/store/actions/employees';
import { USER_ROLES } from '@/store/modules/user';
import { AUTH_REQUEST } from '@/store/actions/auth';
import i18n from '@/i18n';

export default {
  name: 'OnboardingSubmitStep',
  components: {
    BaseButton,
  },
  emits: ['skip', 'submitStep'],
  data() {
    return {
      btnLoading: false,
    };
  },
  computed: {
    ...mapGetters(['onboarding', 'onboardingInsuranceCompany']),
  },
  mounted() {
  },
  methods: {
    submitOnboarding() {
      this.btnLoading = true;
      const operatorsEmails = this.onboarding.operators;
      const saveEmployeeRequests = operatorsEmails ? operatorsEmails.filter((val) => !!val).map((email) => this.$store.dispatch(EMPLOYEE_SAVE_REQUEST, {
        email,
        role: USER_ROLES.INSURANCE_OPERATOR
      })
        .catch((error) => {
          console.error(`Error saving employee with email ${email}:`, error);
        })) : [];

      const saveInsuranceConfig = this.$store.dispatch(SAVE_INSURANCE_CONFIG, {
        name: this.onboarding.company.name,
        logoCdnFileIdSave: this.onboarding.company?.logoCdnFile?.cdnFileId,
      }).catch((error) => {
        console.error('Error saving insurance config:', error);
      });

      Promise.all([Promise.all(saveEmployeeRequests), saveInsuranceConfig])
        .then(([createdEmployees, savedInsuranceConfig]) => {
          this.inviteEmployees(createdEmployees.map((val) => val.grantId));
        }).catch(() => {
          this.finishOnboarding();
        });
    },
    inviteEmployees(grantIds) {
      const request = {
        grantIds,
        sendAt: null,
        locale: i18n.locale,
      };
      this.$store.dispatch(EMPLOYEE_SEND_WELCOME_EMAIL_REQUEST, request).then(() => {
        this.finishOnboarding();
      });
    },
    finishOnboarding() {
      this.$store.dispatch(UPDATE_ONBOARDING, {complete: true});
      this.$store.dispatch(SAVE_ONBOARDING);
      this.$store.dispatch(USER_REQUEST);
    }
  },
};
</script>

<style lang="scss">
.onboarding-integration {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 40px 70px 0px 70px;
  align-items: center;
}

.onboarding-integration__description {
  line-height: 20px !important;
  color: var(--solid-black-purple);
  text-align: center;
}
</style>
